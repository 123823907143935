import React, { useEffect, useState } from "react"
import { Button, Col, Empty, Flex, Space, Spin, Typography } from "antd"

import PostCard from "./components/postCard/PostCard"
import SuggestionsList from "./components/suggestionsList/suggestionsList"
import CommunitiesSuggestions from "../../components/communities-manage-banner/CommunitiesSuggestion"
import AIProfilePreview from "./components/ai-profile-preview/AIProfilePreview"

import { useFeed } from "../../hooks/useFeed"
import useInfiniteScroll from "../../hooks/useInfiniteScroll"

import Sprinkle from "../../assets/images/sprinkle.png"
import { useDispatch, useSelector } from "react-redux"
import { toggleReloadFeed } from "../../redux/slices/socialMedia"

const { Text, Title } = Typography

const SocialMedia = () => {
  const [open, setOpen] = useState(false)
  const {
    loadMore: loadMoreFeedItems,
    posts: socialFeed,
    loading: isLoadingFeed,
    hasMore: hasMoreFeedItem,
    handleComment: addCommentToPost,
    toggleLike: togglePostLike,
    isAddingComment,
    reloadFeed
  } = useFeed({
    feedId: null,
    feedType: "social_feed"
  })
  const shouldReloadFeed = useSelector(state => state.socialMedia.reloadFeed)
  const dispatch = useDispatch()

  useEffect(() => {
    if (shouldReloadFeed) {
      reloadFeed()
      dispatch(toggleReloadFeed(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldReloadFeed])

  const { lastElementRef } = useInfiniteScroll({
    onIntersect: () => {
      if (isLoadingFeed) return
      if (hasMoreFeedItem) {
        loadMoreFeedItems()
      }
    },
    enabled: !isLoadingFeed && socialFeed?.length > 0
  })

  const onClose = () => {
    setOpen(false)
  }

  return (
    <div className="socialMediaScreen">
      <Spin spinning={isLoadingFeed}>
        <Flex gap="48px">
          <Col className="gutter-row" span={16}>
            <div className="socialMediaScroll">
              {socialFeed?.length === 0 ? (
                <Flex
                  justify="center"
                  align="center"
                  vertical
                  style={{ height: "100%" }}
                >
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </Flex>
              ) : (
                socialFeed?.map((feed, index) => {
                  return (
                    <PostCard
                      index={feed?.post_id}
                      attachments={feed?.attachments}
                      author={feed?.author}
                      comments={feed?.comments}
                      comments_count={feed?.comments_count}
                      comments_enabled={feed?.comments_enabled}
                      content={feed?.content}
                      created_at={feed?.created_at}
                      likes_count={feed?.likes_count}
                      post_id={feed?.post_id}
                      likes_enabled={feed?.likes_enabled}
                      user_has_liked={feed?.user_has_liked}
                      likes={feed?.likes}
                      togglePostLike={togglePostLike}
                      addCommentToPost={addCommentToPost}
                      isAddCommentToPostLoading={isAddingComment}
                      feed={feed}
                      key={feed?.post_id}
                      ref={
                        index === socialFeed.length - 1 ? lastElementRef : null
                      }
                    />
                  )
                })
              )}
            </div>
          </Col>
          <Col className="gutter-row" span={8}>
            <Space className="Suggestions-list-wrapper">
              <Flex justify="space-between" align="center" gap={12}>
                <img src={Sprinkle} alt="sprinkle" />
                <Flex vertical justify="space-between" gap={8}>
                  <Flex justify="space-between" align="center">
                    <Title level={4}>My AI Profile</Title>
                    <Button type="link" onClick={() => setOpen(true)}>
                      View Here
                    </Button>
                  </Flex>
                  <Text>
                    Get AI-generated suggestions to enhance your profile
                    content.
                  </Text>
                </Flex>
              </Flex>
            </Space>
            <SuggestionsList />
            <CommunitiesSuggestions />
          </Col>
        </Flex>
      </Spin>
      <AIProfilePreview
        open={open}
        onClose={onClose}
        title="View Your AI-Generated Profile"
      />
    </div>
  )
}

export default SocialMedia
