import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { userHasValidToken } from "../../api/auth"

import PrivacyPolicyUpdated from "../../components/privacy-policy-updated/PrivacyPolicyUpdated"

import { useCheckPrivacyPolicyConsentStatusQuery } from "../../api/apiSlices/settingsApiSlice"

import { Routes } from "../../constants/routes"

const ProtectedRouteValidator = ({ children }) => {
  const {
    data: privacyConsentStatus,
    isLoading: isLoadingPrivacyPolicyStatus,
    refetch: refetchPrivacyPolicyStatus
  } = useCheckPrivacyPolicyConsentStatusQuery()

  const hasValidToken = userHasValidToken()
  let navigate = useNavigate()

  useEffect(() => {
    if (!hasValidToken) {
      navigate(Routes.LOGIN, { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!hasValidToken) return <></>

  return (
    <>
      {children}
      {!isLoadingPrivacyPolicyStatus &&
        !privacyConsentStatus.is_policy_accepted && (
          <PrivacyPolicyUpdated
            refetchPrivacyPolicyStatus={refetchPrivacyPolicyStatus}
          />
        )}
    </>
  )
}

export default ProtectedRouteValidator
