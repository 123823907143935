import { useState, useEffect, useRef } from 'react';
import {
  fetchFeed,
  likePost,
  commentOnPost,
  rejectCommunityPost,
  fetchPost,
} from '../api/apiServices/feedService';
import { getUser } from '../api/auth';
import { useGetProfileDataQuery } from '../api/apiSlices/userApiSlice';
import { notification, Modal } from 'antd';

const { confirm } = Modal;

export const useFeed = ({ feedType, feedId }) => {
  const { data: userProfile } = useGetProfileDataQuery();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isAddingComment, setIsAddingComment] = useState(false);
  const currentUser = getUser();
  const currentFeedId = useRef();

  const loadFeed = async ({ feedId, feedType, currentPage }) => {
    setLoading(true);
    const { data, error: fetchError } = await fetchFeed({
      feedType,
      feedId,
      page: currentPage,
    });
    if (fetchError) {
      setError(fetchError);
    } else {
      if (data) {
        setPosts((prevPosts) => [...prevPosts, ...data.data.results]);
        setHasMore(!!data.data.next);
      }
    }
    setLoading(false);
  };

  const loadSinglePost = async (feedId) => {
    setLoading(true);
    const { data, error: fetchError } = await fetchPost(feedId);
    if (fetchError) {
      setError(fetchError);
    } else {
      if (data) {
        setPosts([data]);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (feedType === 'post') {
      loadSinglePost(feedId);
      return;
    }

    if (feedId !== currentFeedId.current) {
      setPosts([]);
      setCurrentPage(1);
    }
    currentFeedId.current = feedId;
    loadFeed({ feedType, feedId, currentPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedType, feedId, currentPage]);

  const toggleLike = async (postId) => {
    const postIndex = posts.findIndex((post) => post.post_id === postId);
    if (postIndex !== -1) {
      setPosts((prevPosts) => {
        const updatedPosts = [...prevPosts];
        const post = updatedPosts[postIndex];

        if (post.user_has_liked) {
          // User has already liked the post, so we need to unlike it
          post.likes_count -= 1;
          post.likes = post.likes.filter(
            (like) => like.user_id !== currentUser.id,
          );
        } else {
          // User is liking the post
          post.likes_count += 1;
          const newLike = {
            user_id: currentUser.id,
            username: userProfile.name,
            name: userProfile.name,
            profile_picture: userProfile.profile_picture?.url, // Or use a valid profile picture URL
          };
          post.likes.push(newLike);
        }
        post.user_has_liked = !post.user_has_liked; // Toggle the liked status
        return updatedPosts;
      });
    }

    const { error: likeError } = await likePost(postId);
    if (likeError) {
      // Optionally, rollback the optimistic update in case of error
      setPosts((prevPosts) => {
        const updatedPosts = [...prevPosts];
        const post = updatedPosts[postIndex];

        if (post.user_has_liked) {
          // Rollback unlike
          post.likes_count -= 1;
          post.likes = post.likes.filter(
            (like) => like.user_id !== currentUser.id,
          );
        } else {
          // Rollback like
          post.likes_count += 1;
          const existingLikeIndex = post.likes.findIndex(
            (like) => like.user_id === currentUser.id,
          );
          if (existingLikeIndex !== -1) {
            post.likes.splice(existingLikeIndex, 1);
          }
        }
        post.user_has_liked = !post.user_has_liked; // Toggle back the liked status
        return updatedPosts;
      });
    }
  };

  const handleComment = async ({ postId, content }) => {
    setIsAddingComment(true);
    const postIndex = posts.findIndex((post) => post.post_id === postId);
    if (postIndex !== -1) {
      setPosts((prevPosts) => {
        const updatedPosts = [...prevPosts];
        updatedPosts[postIndex] = {
          ...updatedPosts[postIndex],
          comments: [
            ...updatedPosts[postIndex].comments,
            {
              comment_id: new Date().toISOString(), // Temporary ID
              author_profile_picture: userProfile.profile_picture,
              author_name: userProfile.name,
              content: content,
              created_at: new Date().toISOString(),
            },
          ],
          comments_count: updatedPosts[postIndex].comments_count + 1,
        };
        return updatedPosts;
      });
    }

    const { error: commentError } = await commentOnPost({
      postId,
      content,
    });
    if (commentError) {
      setPosts((prevPosts) => {
        const updatedPosts = [...prevPosts];
        updatedPosts[postIndex] = {
          ...updatedPosts[postIndex],
          comments: updatedPosts[postIndex].comments.slice(0, -1),
          comments_count: updatedPosts[postIndex].comments_count - 1,
        };
        return updatedPosts;
      });
    }
    setIsAddingComment(false);
  };

  const handleRejectCommunityPost = async ({ postId, communityId }) => {
    const { error: removeError } = await rejectCommunityPost({
      postId,
      communityId,
    });
    if (!removeError) {
      setPosts((prevPosts) =>
        prevPosts.filter((post) => post.post_id !== postId),
      );

      notification.success({
        message: 'Success',
        description: 'The post has been successfully removed.',
      });
    } else {
      notification.error({
        message: 'Error',
        description: 'There was an issue removing the post. Please try again.',
      });
    }
  };

  const showConfirm = ({ postId, communityId }) => {
    confirm({
      title: 'Are you sure you want to remove this post?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      cancelText: 'Cancel',
      onOk: () => handleRejectCommunityPost({ postId, communityId }),
    });
  };

  const reloadFeed = () => {
    setPosts([]);
    setCurrentPage(1);
    loadFeed({ feedType, feedId, currentPage });
  };

  // Function to load more posts
  const loadMore = () => {
    if (hasMore && !loading) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  return {
    posts,
    loading,
    error,
    loadMore,
    toggleLike,
    handleComment,
    hasMore,
    isAddingComment,
    handleRejectCommunityPost,
    reloadFeed,
    showConfirm,
  };
};
