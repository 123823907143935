import { Flex, Button } from "antd"
import { EditOutlined, DeleteOutlined } from "@ant-design/icons"
import Dayjs from "dayjs"
import duration from "dayjs/plugin/duration"
import SeeMoreText from "../../../components/see-more-text/SeeMoreText"
import { ReactComponent as WorkExp } from "../../../assets/rawSvgs/work-exp.svg"
import "./styles.scss"

// Extend Dayjs with duration plugin
Dayjs.extend(duration)

const JOB_TYPE = {
  full_time: "Full-time",
  part_time: "Part-time",
  remote: "Hybrid"
}
const WorkExperienceCard = ({ workExp, onEditExp, onDeleteWorkExp }) => {
  // Calculate the duration in years and months
  const totalMonths = Dayjs(
    workExp.end_date ? workExp.end_date : new Date()
  ).diff(Dayjs(workExp.start_date), "months")
  const years = Math.floor(totalMonths / 12)
  const months = totalMonths % 12
  const durationString =
    `${years ? `${years} year${years > 1 ? "s" : ""}` : ""} ${months ? `${months} month${months > 1 ? "s" : ""}` : ""}`.trim()


  return (
    <div className="workExp">
      <Flex justify="space-between">
        <Flex gap={8}>
          <WorkExp />
          <h5>{workExp.job_title}</h5>
        </Flex>
        <Flex gap={8}>
          <Button
            type="primary"
            shape="circle"
            icon={<EditOutlined />}
            onClick={() => onEditExp(workExp)}
          />
          <Button
            type="default"
            danger
            shape="circle"
            icon={<DeleteOutlined />}
            onClick={() => onDeleteWorkExp(workExp)}
          />
        </Flex>
      </Flex>
      <div className="workExpDetails">
        <div className="workExpDuration">
          <span className="companyName">{workExp.company_name}</span>{" "}
          <span>{JOB_TYPE[workExp.job_type]}</span>
          <span>{" . "}</span>
          <span>{`${Dayjs(workExp.start_date).format("MMM YYYY")} - ${workExp.currently_working ? "Present" : Dayjs(workExp.end_date).format("MMM YYYY")}`}</span>
          <span>{" . "}</span>
          <span>{durationString}</span>
        </div>
        <div className="mt-1">
          <SeeMoreText content={workExp.description} />
        </div>
      </div>
    </div>
  )
}

// Export the component
export default WorkExperienceCard
