import React from 'react';
import { Avatar, Button, Flex, Typography } from 'antd';

import { useNavigate } from 'react-router-dom';
import './suggestionsList.scss';
import { Routes } from '../../../../constants/routes';
import { UserOutlined } from '@ant-design/icons';

const { Text, Title } = Typography;

const defaultFn = () => '';

function SuggestionsListItem({
  name = '',
  username = '',
  user_id = null,
  profileImage,
  onAddFriend = defaultFn,
}) {
  const navigate = useNavigate();

  return (
    <Flex
      gap="middle"
      justify="space-between"
      align="center"
      className="single-list-item"
      onClick={() => navigate(Routes.PROFILE.replace(':id', user_id))}
    >
      <Flex gap="middle" align="center">
        <Avatar
          size={40}
          src={profileImage}
          icon={<UserOutlined />}
          style={{ minWidth: 40 }}
        />
        <Flex vertical className="single-item-content">
          <Title level={5}>{name}</Title>
          <Text>@{username}</Text>
        </Flex>
      </Flex>
      <Button
        type="link"
        primary
        onClick={(event) => {
          event.stopPropagation();
          onAddFriend(user_id);
        }}
      >
        Add Friend
      </Button>
    </Flex>
  );
}

export default SuggestionsListItem;
