import React from "react"
import {
  Avatar,
  Button,
  Card,
  Divider,
  Drawer,
  Flex,
  Space,
  Spin,
  Typography
} from "antd"

import SuggestionsListItem from "../suggestionsList/suggestionsListItem"

import { openNotification } from "../../../../util/openNotifications"

import {
  useAddUserConnectionMutation,
  useGetUserInsightsQuery
} from "../../../../api/apiSlices/socialMediaApiSlice"

import {
  ThumbsDownIcon,
  ThumbsUpIcon,
  EllipseIcon
} from "../../../../assets/rawSvgs"

import styles from "./aiPreview.module.scss"

const { Text, Title } = Typography

const AIProfilePreview = ({ open = false, onClose = () => "", title = "" }) => {
  const {
    data: userInsights,
    isLoading: isUserInsightsLoading,
    isFetching: isUserInsightsFetching,
    refetch: refetchUserInsights
  } = useGetUserInsightsQuery()

  const [addUserConnection] = useAddUserConnectionMutation()

  const onAddConnection = async id => {
    const payload = {
      connected_user: id
    }
    await addUserConnection(payload)
      .unwrap()
      .then(() => {
        openNotification({
          message: "A connection request has been sent",
          type: "success"
        })
      })
  }

  const suggestions = []
  const communities = [1, 1, 1, 1, 1, 1]
  return (
    <>
      <Drawer
        open={open}
        className="ai_profile_preview_drawer"
        placement="right"
        onClose={onClose}
        title={title}
        width={491}
        loading={isUserInsightsFetching || isUserInsightsLoading}
        extra={
          <Button
            disabled={isUserInsightsFetching}
            onClick={() => refetchUserInsights()}
          >
            Refresh View
          </Button>
        }
        footer={
          <Flex gap={16} justify="space-between" align="center">
            <Text className="text-sm-400">
              How would you rate your AI-generated profile preferences?
            </Text>
            <Space>
              <Button icon={<ThumbsUpIcon />} style={{ padding: "8px" }} />
              {/* <Divider type="vertical" /> */}
              <Button icon={<ThumbsDownIcon />} style={{ padding: "8px" }} />
            </Space>
          </Flex>
        }
      >
        <Space className={styles.ai_profile_preview_wrapper}>
          <Flex vertical gap={24}>
            <Text>
              Discover insights tailored just for you! Our AI analyzes your
              activity and preferences to create a personalized profile that
              highlights what you love most.
            </Text>
            <Flex vertical gap={16}>
              <Title level={4} className={styles.suggestionsListTitle}>
                Suggested Connections
              </Title>
              {suggestions?.length > 0 ? (
                suggestions?.map(suggestion => {
                  return (
                    <SuggestionsListItem
                      name={suggestion?.name}
                      username={suggestion?.username}
                      user_id={suggestion?.user_id}
                      onAddFriend={onAddConnection}
                    />
                  )
                })
              ) : (
                <Text>No suggestion Found</Text>
              )}
            </Flex>
            <Flex vertical gap={16}>
              <Title level={4}>Preffered Communities</Title>
              <Flex gap={12} align="center" wrap>
                {/* {userInsights?.preferred_communities?.length > 0 ? (
                  userInsights?.preferred_communities?.map(pCommunities => { */}
                {communities?.length > 0 ? (
                  communities?.map(pCommunities => {
                    return (
                      <Card
                        style={{
                          width: 160
                        }}
                        // cover={
                        //   <img
                        //     alt="example"
                        //     src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"
                        //   />
                        // }
                        className="outer-wrapper"
                      >
                        <div className={styles.CommunityCardCover}>
                          <img
                            style={{ height: "50px", width: "100%" }}
                            className={styles.communityImage}
                            alt="example"
                            src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"
                          />
                        </div>
                        <Flex
                          vertical
                          align="center"
                          gap={12}
                          className="inner-wrapper"
                        >
                          <Avatar size={60} />
                          <Flex vertical align="center">
                            <Text className="text-sm-500">Eco Warriors 🌱</Text>
                            <Flex gap={4} align="center">
                              <Text
                                className="text-xs-500"
                                style={{ color: "#000000A6" }}
                              >
                                157 Members
                              </Text>
                              <EllipseIcon />
                              <Text
                                className="text-xs-500"
                                style={{ color: "#000000A6" }}
                              >
                                Private
                              </Text>
                            </Flex>
                          </Flex>
                          <Button>View</Button>
                        </Flex>
                      </Card>
                    )
                  })
                ) : (
                  <Text>No Community Found</Text>
                )}
              </Flex>
            </Flex>
            <Flex vertical gap={16}>
              <Title level={4}>Most Engaged Topics</Title>
              {communities?.length > 0 ? (
                communities?.map(suggestion => {
                  return (
                    <Card>
                      <Flex gap={12}>
                        <Avatar size={40} style={{ flex: 1 }} />
                        <Flex vertical gap={4} flex={9}>
                          <Title level={5}>Science and whatever it holds</Title>
                          <Text>
                            Asperiores excepturi magnam dolores fut.Labore
                            exercitationem maiores qui vel.p.Ullam rerum vitae
                            nulla consectetur cow.Sed exercitationem nemo et,
                            exercitatiq.Commodi dolores consequatur doloribus.r.
                          </Text>
                        </Flex>
                      </Flex>
                    </Card>
                  )
                })
              ) : (
                <Text>No suggestion Found</Text>
              )}
            </Flex>
          </Flex>
        </Space>
      </Drawer>
    </>
  )
}

export default AIProfilePreview
