import React from 'react'
import { Button , Card} from 'antd';
import { CloseOutlined } from '@ant-design/icons';

function Header({closePopover}) {


  return (
    <>
          
            <div style={headerStyle}>
           
            <div
             style={{ cursor: 'pointer', marginLeft: 'auto'  }}
             onClick={closePopover}
              >
            <CloseOutlined />
         </div>
                
           </div>

          
      

    </>
   
  )
}


const headerStyle = {
  display:'flex',
  paddingTop: '24px',
  paddingRight: '30px',
  paddingBottom: '8px',
  paddingLeft: '24px',

   
  };



export default Header
