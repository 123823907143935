import React from 'react'
import { Flex, Button } from "antd"
import { EditOutlined, DeleteOutlined } from "@ant-design/icons"
import Dayjs from "dayjs"
import duration from "dayjs/plugin/duration"
import SeeMoreText from "../../../components/see-more-text/SeeMoreText"
import { ReactComponent as WorkExp } from "../../../assets/rawSvgs/work-exp.svg"
import "./styles.scss"
Dayjs.extend(duration)

const EducationExperienceCard = ({ workExp, onEditEdu, onDeleteEdu }) => {


  return (
    <div className="workExp">
      <Flex justify="space-between">
        <Flex gap={8}>
          <WorkExp />
          <h5>{workExp.school}</h5>
        </Flex>
        <Flex gap={8}>
          <Button
            type="primary"
            shape="circle"
            icon={<EditOutlined />}
             onClick={() => onEditEdu(workExp)}
          />
          <Button
            type="default"
            danger
            shape="circle"
            icon={<DeleteOutlined />}
            onClick={() => onDeleteEdu(workExp)}
          />
        </Flex>
      </Flex>
      <div className="workExpDetails">
        <div className="workExpDuration">
          <span className="companyName">{workExp.degree}</span>{" "}
          <span>{workExp.field_of_study}</span>
          <span>{" . "}</span>
          {/* <span>
       {`${Dayjs(workExp.start_month).format("MMM")} ${workExp.start_year} - ${Dayjs(workExp.end_month).format("MMM")} ${workExp.end_year}`}
             </span> */}
          <span>
  {Dayjs(workExp.start_month).isValid() && Dayjs(workExp.start_year).isValid()
    ? `${Dayjs(workExp.start_month).format("MMM")} ${workExp.start_year}`
    : "N/A"} 
  {" - "}
  {Dayjs(workExp.end_month).isValid() && Dayjs(workExp.end_year).isValid()
    ? `${Dayjs(workExp.end_month).format("MMM")} ${workExp.end_year}`
    : "N/A"}
</span>

          {/* <span>{`${Dayjs(workExp.start_date).format("MMM YYYY")} - ${Dayjs(workExp.end_time).format("MMM YYYY")}`}</span> */}
          <span>{" . "}</span>
          {/* <span>{durationString}</span> */}
        </div>
        <div className="mt-1">
          <SeeMoreText content={workExp.description} />
        </div>
      </div>
    </div>
  )
}

export default EducationExperienceCard
