import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { StreamChat } from "stream-chat"
import {
  Chat,
  Channel,
  ChannelList,
  Window,
  ChannelHeader,
  MessageList,
  MessageInput,
  Thread,
  Avatar,
  useChannelStateContext,
  InfiniteScroll
} from "stream-chat-react"

import { getQueryParams } from "../../util/getQueryParams"

import { getStreamToken, getUser, getUserId } from "../../api/auth"

import { ROLES } from "../../constants/roles"
import { CHANNEL_TYPES } from "../../constants/chat"

import "./layout.modules.css"

const apiKey = "7xg9zsjstaep"

const client = StreamChat.getInstance(apiKey)

const ChatManage = () => {
  const userId = getUserId()
  const user = getUser()
  const currentRole = getUser()?.role
  const streamToken = getStreamToken()

  const location = useLocation()

  const { channel_id } = getQueryParams(location.search)

  const handleChatFilter = role => {
    switch (role) {
      case ROLES.SOCIAL_USER:
        return [CHANNEL_TYPES.COMMUNITY_CHAT, CHANNEL_TYPES.USER_TO_USER_CHAT]
      case ROLES.EMPLOYER:
      case ROLES.WORKER:
        return [CHANNEL_TYPES.MESSAGING]
      default:
        return null
    }
  }

  const customChannelPreview = props => {
    const { channel, setActiveChannel, activeChannel } = props

    const otherMember = Object.values(channel.state.members).find(
      member => member.user.id !== userId
    )

    const isActive = activeChannel?.id === channel.id

    return (
      <div
        className={`custom-channel-preview ${isActive ? "selected" : ""}`}
        onClick={() => setActiveChannel(channel)}
      >
        <Avatar
          image={
            channel?.data?.type === CHANNEL_TYPES.COMMUNITY_CHAT
              ? channel?.data?.image?.url || ""
              : otherMember?.user?.image || ""
          }
          name={otherMember?.user.name}
          size={40}
        />
        <div className="channel-preview-content">
          <span className="channel-preview-title">
            {channel?.data?.type === CHANNEL_TYPES.COMMUNITY_CHAT
              ? channel?.data?.name || "Chat"
              : otherMember?.user.name || "Chat"}
          </span>
          <span className="channel-preview-last-message">
            {channel.state.messages[channel.state.messages.length - 1]?.text ||
              "No messages yet."}
          </span>
        </div>
      </div>
    )
  }

  const CustomChannelHeader = () => {
    const { channel } = useChannelStateContext()

    const otherMember = Object.values(channel.state.members).find(
      member => member.user.id !== getUserId()
    )

    console.log("channel", channel)

    return (
      <div className="custom-channel-header">
        <Avatar
          image={
            channel?.data?.type === CHANNEL_TYPES.COMMUNITY_CHAT
              ? channel?.data?.image?.url || ""
              : otherMember?.user.image || ""
          }
          name={otherMember?.user.name || "Anonymous"}
          size={40}
        />
        <div className="channel-header-content">
          <span className="channel-header-title">
            {channel?.data?.type === CHANNEL_TYPES.COMMUNITY_CHAT
              ? channel?.data?.name || "Chat"
              : otherMember?.user?.name || "Chat"}
          </span>
          <span className="channel-header-subtitle">
            {channel.data.member_count > 2
              ? `${channel.data.member_count} members`
              : "Direct message"}
          </span>
        </div>
      </div>
    )
  }

  useEffect(() => {
    const init = async () => {
      if (!userId || !streamToken) {
        console.error("User ID or Stream Token is missing.")
        return
      }

      try {
        await client.connectUser({ id: userId }, streamToken)
      } catch (error) {
        console.error("Error connecting user to Stream Chat:", error)
      }
    }

    init()
  }, [userId, streamToken])

  if (!client) return <div>Setting up client & connection...</div>
  const filters = {
    // type: handleChatFilter(currentRole),
    ...(handleChatFilter(currentRole) && {
      type: { $in: handleChatFilter(currentRole) }
    }),
    members: { $in: [userId] }
  }
  const sort = [{ last_message_at: -1 }]
  return (
    <div className="chat-management-container">
      <Chat client={client}>
        <ChannelList
          filters={filters}
          sort={sort}
          showChannelSearch
          customActiveChannel={channel_id || ""}
          Preview={customChannelPreview}
          Paginator={InfiniteScroll}
        />

        <Channel>
          <Window>
            {/* <ChannelHeader /> */}
            <CustomChannelHeader />
            <MessageList />
            <MessageInput />
          </Window>
          <Thread />
        </Channel>
      </Chat>
    </div>
  )
}

export default ChatManage
