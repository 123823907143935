import React, { useEffect, useMemo, useState } from 'react';
import { Avatar, Col, Empty, Flex, Spin, Typography } from 'antd';

import Header from './components/header/Header';
import { useGetCommunityDetailsQuery } from '../../api/apiSlices/communityApiSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { useIsPartOfCommunity } from '../../util/useIsPartOfCommunity';
import { useIsPrivateCommunity } from '../../util/useIsPrivateCommunity';
import { ReactComponent as PrivateCommunity } from '../../assets/communities/privateCommunity.svg';
import { useIsCommunityAdmin } from '../../util/useIsCommunityAdmin';
import CommunitiesSuggestions from '../../components/communities-manage-banner/CommunitiesSuggestion';
import CustomTextArea from '../../components/customInput/CustomTextArea';
import PostCard from '../social-media/components/postCard/PostCard';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import { useFeed } from '../../hooks/useFeed';
import CreatePost from '../social-media/components/create-post/CreatePost';
import { useGetSocialMediaProfileQuery } from '../../api/apiSlices/socialMediaApiSlice';
import { getUser } from '../../api/auth';
import { openNotification } from '../../util/openNotifications';
import { Routes } from '../../constants/routes';
import './Community.scss';
import { UserOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const Community = () => {
  let { id: communityId } = useParams();
  const [isOpenCreatePostModal, setIsCreateOpenPostModal] = useState(false);
  const navigate = useNavigate();

  const {
    loadMore: loadMoreFeedItems,
    posts: socialFeed,
    loading: isLoadingFeed,
    hasMore: hasMoreFeedItems,
    handleComment: addCommentToPost,
    toggleLike: togglePostLike,
    showConfirm,
    reloadFeed,
    isAddingComment,
  } = useFeed({
    feedId: communityId,
    feedType: 'community',
  });

  const currentUser = getUser();
  const { data: userProfile } = useGetSocialMediaProfileQuery(currentUser.id);

  const {
    data: communityDetails,
    isLoading: isLoadingDetails,
    error: communityErrors,
  } = useGetCommunityDetailsQuery(communityId, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (communityErrors?.errors) {
      openNotification({ message: communityErrors.message, type: 'error' });
      navigate(Routes.COMMUNITIES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityErrors]);

  const [isJoined] = useIsPartOfCommunity(communityId);

  const isPrivateCommunity = useIsPrivateCommunity(
    communityDetails,
    isLoadingDetails,
  );

  const isAdmin = useIsCommunityAdmin(
    communityDetails?.admins,
    isLoadingDetails,
  );

  const openCreatePostModal = () => setIsCreateOpenPostModal(true);

  const onCloseCreatePost = ({ promises = [], newPostCreated }) => {
    if (newPostCreated) {
      reloadFeed();
    }

    setIsCreateOpenPostModal(false);
    if (promises.length > 0) {
      Promise.all(promises);
    }
  };

  const { lastElementRef } = useInfiniteScroll({
    onIntersect: () => {
      if (isLoadingFeed) return;
      if (hasMoreFeedItems) {
        loadMoreFeedItems();
      }
    },
    enabled: !isLoadingFeed && socialFeed?.length > 0,
  });

  const shouldShowPosts = useMemo(() => {
    if (isLoadingDetails) return false;
    if (isAdmin) return true;
    if (isPrivateCommunity && !isJoined) return false;
    return true;
  }, [isLoadingDetails, isPrivateCommunity, isJoined, isAdmin]);

  const canPost = isJoined || isAdmin;

  return (
    <Spin spinning={isLoadingDetails || isLoadingFeed}>
      <div className="communityPage">
        <Header />
        <Flex gap="48px">
          <Col className="gutter-row" span={16}>
            {!isLoadingDetails && !shouldShowPosts && (
              <Flex
                gap={8}
                vertical
                align="center"
                style={{ marginTop: '32px' }}
              >
                <PrivateCommunity />
                <Title level={5}>This is a Private Community 🔒</Title>
                <Text style={{ textAlign: 'center' }}>
                  Join to gain access to posts, discussions, and more. Be part
                  of the inner circle and connect with like-minded members. 🌟
                </Text>
              </Flex>
            )}
            <div>
              {canPost && (
                <div className="createPost">
                  <Avatar
                    size={50}
                    src={userProfile?.profile_picture?.url}
                    icon={<UserOutlined />}
                  />
                  <div
                    className="createPostTrigger"
                    onClick={openCreatePostModal}
                  >
                    <CustomTextArea
                      placeholder="What’s on your mind..."
                      className="createPostTrigger"
                      onClick={openCreatePostModal}
                      inputProps={{
                        autoSize: {
                          minRows: 1,
                          maxRows: 7,
                        },
                      }}
                    />
                  </div>
                  {isOpenCreatePostModal && (
                    <CreatePost
                      onClose={onCloseCreatePost}
                      communityId={communityId}
                    />
                  )}
                </div>
              )}

              {shouldShowPosts && socialFeed?.length === 0 && (
                <Flex
                  justify="center"
                  align="center"
                  vertical
                  style={{ height: '100%' }}
                >
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No Posts yet"
                  />
                </Flex>
              )}
              <div className="community-feed">
                {shouldShowPosts &&
                  socialFeed?.map((feed, index) => {
                    return (
                      <PostCard
                        index={feed?.post_id}
                        attachments={feed?.attachments}
                        author={feed?.author}
                        comments={feed?.comments}
                        comments_count={feed?.comments_count}
                        comments_enabled={feed?.comments_enabled}
                        content={feed?.content}
                        created_at={feed?.created_at}
                        likes_count={feed?.likes_count}
                        post_id={feed?.post_id}
                        likes_enabled={feed?.likes_enabled}
                        user_has_liked={feed?.user_has_liked}
                        likes={feed?.likes}
                        feed={feed}
                        togglePostLike={togglePostLike}
                        addCommentToPost={addCommentToPost}
                        isAddCommentToPostLoading={isAddingComment}
                        key={feed?.post_id}
                        isAdminView={isAdmin}
                        onRemoveCommunityPost={showConfirm}
                        communityId={communityId}
                        ref={
                          index === socialFeed.length - 1
                            ? lastElementRef
                            : null
                        }
                        isCommunityView
                      />
                    );
                  })}
              </div>
            </div>
          </Col>
          <Col className="gutter-row" span={8}>
            <CommunitiesSuggestions />
          </Col>
        </Flex>
      </div>
    </Spin>
  );
};

export default Community;
