import React, { useEffect } from "react"
import { Col, Empty, Flex, Spin } from "antd"

import CommunitiesSuggestions from "../../components/communities-manage-banner/CommunitiesSuggestion"
import CommunitiesManageBanner from "../../components/communities-manage-banner/CommunitiesManageBanner"
import { useFeed } from "../../hooks/useFeed"
import PostCard from "../social-media/components/postCard/PostCard"
import useInfiniteScroll from "../../hooks/useInfiniteScroll"

import JoinedCommunities from "./JoinedCommunities"
import { useDispatch, useSelector } from "react-redux"
import { toggleReloadFeed } from "../../redux/slices/socialMedia"
import "./Communities.scss"

const Communities = () => {
  const {
    loadMore: loadMoreFeedItems,
    posts: socialFeed,
    loading: isLoadingFeed,
    hasMore: hasMoreFeedItems,
    handleComment: addCommentToPost,
    toggleLike: togglePostLike,
    isAddingComment,
    reloadFeed
  } = useFeed({
    feedId: null,
    feedType: "communities"
  })
  const shouldReloadFeed = useSelector(state => state.socialMedia.reloadFeed)
  const dispatch = useDispatch()

  useEffect(() => {
    if (shouldReloadFeed) {
      reloadFeed()
      dispatch(toggleReloadFeed(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldReloadFeed])

  const { lastElementRef } = useInfiniteScroll({
    onIntersect: () => {
      if (isLoadingFeed) return
      if (hasMoreFeedItems) {
        loadMoreFeedItems()
      }
    },
    enabled: !isLoadingFeed && socialFeed?.length > 0
  })

  return (
    <Spin spinning={isLoadingFeed}>
      <div className="communityPage">
        <Flex gap="48px">
          <Col className="gutter-row" span={16}>
            <div>
              {socialFeed?.length === 0 && (
                <Flex
                  justify="center"
                  align="center"
                  vertical
                  style={{ height: "100%" }}
                >
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No Posts yet"
                  />
                </Flex>
              )}
              <div className="community-feed">
                {socialFeed?.map((feed, index) => {
                  return (
                    <PostCard
                      index={feed?.post_id}
                      attachments={feed?.attachments}
                      author={feed?.author}
                      comments={feed?.comments}
                      comments_count={feed?.comments_count}
                      comments_enabled={feed?.comments_enabled}
                      content={feed?.content}
                      created_at={feed?.created_at}
                      likes_count={feed?.likes_count}
                      post_id={feed?.post_id}
                      likes_enabled={feed?.likes_enabled}
                      user_has_liked={feed?.user_has_liked}
                      likes={feed?.likes}
                      feed={feed}
                      togglePostLike={togglePostLike}
                      addCommentToPost={addCommentToPost}
                      isAddCommentToPostLoading={isAddingComment}
                      key={feed?.post_id}
                      ref={
                        index === socialFeed.length - 1 ? lastElementRef : null
                      }
                    />
                  )
                })}
              </div>
            </div>
          </Col>
          <Col className="gutter-row" span={8}>
            <JoinedCommunities />
            <CommunitiesManageBanner />
            <CommunitiesSuggestions />
          </Col>
        </Flex>
      </div>
    </Spin>
  )
}

export default Communities
