import React, { useState } from 'react';
import { Avatar, Button, Empty, Flex, Modal, Typography } from 'antd';

import CustomInput from '../../../../components/customInput/CustomInput';

import { useGetUserConnectionsQuery } from '../../../../api/apiSlices/socialMediaApiSlice';

import { useDebounce } from '../../../../hooks/useDebounce';

import {
  SearchOutlined,
  UserAddOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../../constants/routes';
import { useInviteMemberToCommunityMutation } from '../../../../api/apiSlices/communityApiSlice';
import { openNotification } from '../../../../util/openNotifications';

const { Text } = Typography;

const InviteMembers = ({ communityId, memberIds = [] }) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();

  const debouncedSearchTerm = useDebounce(search, 500);

  const [_, contextHolder] = Modal.useModal();

  const { data: connections, isFetching } = useGetUserConnectionsQuery(
    { searchTerm: debouncedSearchTerm, communityId }, // Pass as a single object
    { skip: !open },
  );

  const [inviteToCommunity] = useInviteMemberToCommunityMutation();

  const showModal = () => {
    setOpen(true);
  };

  const inviteMember = async (id) => {
    await inviteToCommunity({ recepientId: id, communityId })
      .unwrap()
      .then(() => {
        openNotification({
          type: 'success',
          message: 'Invite sent',
        });
      })
      .catch((error) => {
        openNotification({
          type: 'error',
          message: error.errors,
        });
      });
  };

  const filteredConnections = connections?.length
    ? connections.filter((connection) => !memberIds.includes(connection.id))
    : [];

  return (
    <>
      <Modal
        open={open}
        centered
        title="Invite"
        onCancel={() => setOpen(false)}
        footer={null}
      >
        <div className="feedbackModal">
          <Flex vertical gap="middle">
            <CustomInput
              placeholder="Search"
              suffix={
                <SearchOutlined style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
              }
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            {filteredConnections?.map((member, index) => {
              return (
                <Flex key={index} justify="space-between" align="center">
                  <Flex
                    gap="small"
                    onClick={() =>
                      navigate(Routes.PROFILE.replace(':id', member.user_id))
                    }
                    className="cursor-pointer"
                  >
                    <Avatar
                      size="small"
                      icon={<UserOutlined />}
                      src={member.connected_user?.profile_picture?.url}
                    />
                    <Text>{member.connected_user.username || 'Anonymous'}</Text>
                  </Flex>
                  <Flex gap="small">
                    <Button
                      onClick={() =>
                        inviteMember(member.connected_user.user_id)
                      }
                    >
                      Invite
                    </Button>
                  </Flex>
                </Flex>
              );
            })}
            {!isFetching && filteredConnections?.length === 0 && (
              <Empty description="Only connections can be invited to a community, Make new connections to grow this community" />
            )}
          </Flex>
        </div>
      </Modal>
      <Button icon={<UserAddOutlined />} onClick={showModal}>
        Invite
      </Button>
      {contextHolder}
    </>
  );
};

export default InviteMembers;
