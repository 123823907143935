import React from "react"
import {
  Button,
  Card,
  Flex,
  Space,
  Typography,
  Col,
  Row,
  Tag,
  Image,
  Spin
} from "antd"
import { ClockCircleOutlined, GlobalOutlined } from "@ant-design/icons"

import { getUser } from "../../api/auth"

import { PlusIcon } from "../../assets/rawSvgs"
import workerEmpty from "../../assets/common/WorkerEmpty.png"

import { Routes } from "../../constants/routes"

import useRouterNavigation from "../../hooks/useNavigate"

import { useGetJobRequestsQuery } from "../../api/apiSlices/flexTimeApiSlice"

import styles from "./flexTimeDashboard.module.scss"
import './styles.scss'
import { useSelector } from "react-redux"

const { Title, Paragraph, Text } = Typography

const FlexTimeDashboard = () => {
  const currentUser = getUser()
  const { navigateTo } = useRouterNavigation()
  const role = useSelector(state =>
    state?.auth?.role ? state?.auth?.role : ""
  )

  const {
    data: jobRequestsList,
    isLoading: isJobRequestListLoading,
    refetch: refetchJobRequestList
  } = useGetJobRequestsQuery({ status: "active", role })

  const isEmployer = role === "employer"
  const isWorker = role === "worker"
  const isListEmpty = jobRequestsList?.length === 0

  const JobCard = ({
    title = "",
    description = "",
    location = "",
    end_date = "",
    start_date = "",
    number_of_workers = 0,
    rate = 5,
    budget_max = "",
    budget_min = "",
    skills = []
  }) => (
    <Col span={8}>
      <Card className="h-100 p-1 my-scrollable-div" size="small" style={{ maxHeight: '400px', overflowY: 'auto' }}>
        <Flex vertical>
          <Title level={5}>{title ?? "N/A"}</Title>
          <Paragraph>{description ?? "N/A"}</Paragraph>
          <Space direction="vertical" size={12}>
            <Row gutter={[12, 12]} align="middle">
              <Col>
                <Flex align="middle" gap={4}>
                  <GlobalOutlined />
                  <Text>{location ?? "N/A"}</Text>
                </Flex>
              </Col>
              <div className="dot dot_small"></div>
              <Col>
                <Flex align="middle" gap={4}>
                  <ClockCircleOutlined />
                  <Text>
                    {start_date} - {end_date}
                  </Text>
                </Flex>
              </Col>
              <div className="dot dot_small"></div>
              <Col>
                <Flex align="middle" gap={4}>
                  <Text>{number_of_workers}</Text>
                </Flex>
              </Col>
              <div className="dot dot_small"></div>
              <Col>
                <Row align="middle">
                  <Tag color="blue">
                    ${budget_min} - ${budget_max} /hr
                  </Tag>
                </Row>
              </Col>
            </Row>

            <Row style={{ marginTop: 16 }}>
              <Space wrap>
                {skills?.map(skill => (
                  <div className={styles.SkillsPill}>{skill}</div>
                ))}
              </Space>
            </Row>
          </Space>
        </Flex>
      </Card>
    </Col>
  )

  const EmptyState = ({ onCreateClick }) => (
    <>
      {onCreateClick ? (
        <Col span={8}>
          <Flex vertical>
            <Button
              type="dashed"
              icon={<PlusIcon stroke="#00000073" />}
              className={`text-lg-500 ${styles.create_gig_btn}`}
              onClick={() => navigateTo(Routes.CREATION)}
            >
              Create Gig
            </Button>
          </Flex>
        </Col>
      ) : (
        <Flex vertical justify="center" align="center" className="w-100">
          <Image src={workerEmpty} preview={false} />
          <Text className="text-lg-500" style={{ color: "#00000073" }}>
            You currently do not have any ongoing projects.
          </Text>
        </Flex>
      )}
    </>
  )

  return (
    <div>
      <Spin spinning={isJobRequestListLoading}>
      <Title level={4} style={{ marginBottom: "26px" }}>
        👋 Hey {currentUser?.name ?? "Anonymous"}
      </Title>
      <Flex vertical gap={16}>
        <Flex justify="space-between" align="center">
          <Title level={5}>Ongoing Projects</Title>
          {isEmployer && !isListEmpty && (
            <Button
              icon={<PlusIcon stroke="#FFFFFF" />}
              onClick={() => navigateTo(Routes.CREATION)}
              type="primary"
            >
              Create New
            </Button>
          )}
        </Flex>
        <Row gutter={[16, 24]}>
          {isEmployer && isListEmpty ? (
            <EmptyState onCreateClick={() => navigateTo(Routes.CREATION)} />
          ) : isWorker && isListEmpty ? (
            <EmptyState />
          ) : (
            <>
              {jobRequestsList?.map(request => (
                           
                 <JobCard  {...request} />
                

              ))}
            </>
          )}
        </Row>
      </Flex>
      </Spin>
    </div>
  )
}

export default FlexTimeDashboard
