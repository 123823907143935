import React, { useState } from 'react';
import { Typography, Button, Space, Card ,Flex } from 'antd';
import { SaveIcon } from '../../../../../assets/rawSvgs';
import { DeleteIcon3 } from '../../../../../assets/rawSvgs';
import { ArrowLeftOutlined } from '@ant-design/icons';
import Header from './Header';


const { Title, Text } = Typography;

function ViewSaved({ saveItems , closePopover ,toggleSavedItemsView}) {
 

 

  return (
    <>
          <div style={savedItemsStyle}>
             {/* <Header closePopover={closePopover}></Header> */}
             <>
             <div style={headerStyle}>
              
              
               <ArrowLeftOutlined onClick={()=>toggleSavedItemsView()}/>
               <Title level={5}>Saved</Title>
              </div>
              </>
            {saveItems.map((item, index) => (
              <Card key={index} style={{ marginBottom: '10px', maxWidth: '324px' }}>
                <div style={{ wordWrap: 'break-word' }}>
                  <Space direction="vertical" >
                    <Text type="secondary">Tell one line story</Text>
                    <Text>{item}</Text>

                    <div >
                       
                       <Flex gap="200px">
                        <Button>Insert</Button>
                        {/* <Button onClick={() => console.log('Save clicked for:', item)} icon={<DeleteIcon3/>} /> */}
                        
                        <span onClick={() => console.log('Save clicked for:', item)} style={{ cursor: 'pointer' }}>
                          <DeleteIcon3 style={{ color: 'red' }} />
                        </span>
                        </Flex>
                    </div>
                  </Space>
                </div>
              </Card>
            ))}
          </div>
      
    </>
  );
}


const cardStyle = {
  

};

const containerStyle = {
  paddingLeft: '20px', 
  backgroundColor:'red'
};

const savedItemsStyle = {
  maxHeight: '200px',
  overflowY: 'auto',
 
};

const headerStyle = {
 
  gap:'20px',
  display:'Flex',
  alignItems:'center'
};
export default ViewSaved;
