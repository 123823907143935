import React, { forwardRef, useState } from "react"
import {
  Avatar,
  Space,
  Typography,
  Flex,
  Button,
  Image,
  Input,
  Carousel,
  Dropdown
} from "antd"
import { UserOutlined } from "@ant-design/icons"
import { getUser } from "../../../../api/auth"
import { useGetSocialMediaProfileQuery } from "../../../../api/apiSlices/socialMediaApiSlice"
import moment from "moment"
import data from "@emoji-mart/data"
import Picker from "@emoji-mart/react"
import {
  SmileOutlined,
  UpOutlined,
  MoreOutlined,
  HeartOutlined,
  DownOutlined
} from "@ant-design/icons"
import {
  RedHeart,
  EmptyHeart,
  CommentBox,
  FilledCommentBox
} from "../../../../assets/rawSvgs"
import { useNavigate } from "react-router-dom"
import CommunityHeader from "./CommunityHeader"
import "./postCard.scss"

const { Text, Title } = Typography

const defaultAsyncFn = async () => ""

function PostCard(
  {
    index,
    content = "",
    attachments = [],
    author = null,
    comments = [],
    comments_count = 0,
    comments_enabled,
    post_id = null,
    created_at = null,
    user_has_liked = false,
    likes_enabled,
    likes,
    likes_count,
    isAddCommentToPostLoading = false,
    isAdminView = false,
    communityId = null,
    feed = { community_detail: null },
    isCommunityView = false,
    addCommentToPost = defaultAsyncFn,
    togglePostLike = defaultAsyncFn,
    onRemoveCommunityPost = () => {}
  },

  ref
) {
  const user = getUser()
  const { community_detail } = feed
  const [showCommentsSection, setShowCommentsSection] = useState(false)
  const [showComments, setShowComments] = useState(false)
  const [comment, setComment] = useState("")
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)

  const currentUser = getUser()
  const { data: userProfile } = useGetSocialMediaProfileQuery(currentUser.id)

  const navigate = useNavigate()

  const handleAddComment = async (id, comment) => {
    const payload = {
      postId: id,
      content: comment
    }
    addCommentToPost(payload)
    setComment("")
  }

  const addEmoji = emoji => {
    setComment(comment + emoji.native)
  }

  const items = [
    {
      label: (
        <span
          onClick={() =>
            onRemoveCommunityPost({ communityId, postId: post_id })
          }
        >
          Remove Post
        </span>
      )
    }
  ]

  const navigateToUserProfile = () => {
    navigate(`/profile/${author.user_id}`)
  }

  return (
    <div className="post-card-wrapper" index={index} ref={ref}>
      {!isCommunityView && community_detail && (
        <CommunityHeader
          communitDetail={community_detail}
          authorDetail={author}
          createdAt={created_at}
        />
      )}
      {(!community_detail || isCommunityView) && (
        <Flex align="center" gap="8px" className="post-card-header">
          <Avatar
            icon={<UserOutlined/>}
            size={32}
            onClick={navigateToUserProfile}
            src={author?.profile_picture?.url}
          />
          <Title level={5}>{author?.name ?? "Anonymous"}</Title>
          <Space>
            <span></span>
            <Text>{moment(created_at).fromNow()}</Text>
          </Space>
          {isAdminView && (
            <div style={{ marginLeft: "auto" }}>
              <Dropdown
                menu={{
                  items
                }}
                trigger={["click"]}
              >
                <Button icon={<MoreOutlined />} />
              </Dropdown>
            </div>
          )}
        </Flex>
      )}

      <div className="post-card-body">
        <Text>{content}</Text>

        <Carousel arrows={attachments?.length > 1}>
          {attachments?.map(attachment => {
            return (
              <>
                {attachment?.type === "image" ? (
                  <Image
                    className="post-image-wrapper"
                    src={attachment?.presigned_url?.url}
                  />
                ) : (
                  <div className="video-cont">
                    <video
                      className="video"
                      controls
                      src={attachment?.presigned_url?.url}
                      width="100%"
                    >
                      <source
                        src={attachment?.presigned_url?.url}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                )}
              </>
            )
          })}
        </Carousel>

        <Flex
          justify="space-between"
          align="center"
          className="post-action-details"
        >
          {author?.user_id === user.id && likes?.length > 0 && (
            <Flex gap="small" align="center">
              <Button
                shape="circle"
                icon={<HeartOutlined />}
                className="like-action-btn"
              />
              <Text>{likes_count}</Text>
            </Flex>
          )}
        </Flex>
        <Flex gap="small" className="post-card-actions">
          <Button
            type="text"
            iconPosition="start"
            icon={user_has_liked ? <RedHeart /> : <EmptyHeart />}
            onClick={() => {
              if (!likes_enabled) return
              togglePostLike(post_id)
            }}
            disabled={!likes_enabled}
          >
            {user_has_liked ? "Liked" : "Like"}
          </Button>
          {comments_enabled && (
            <Button
              type="text"
              iconPosition="start"
              icon={
                !showCommentsSection ? <CommentBox /> : <FilledCommentBox />
              }
              onClick={() => setShowCommentsSection(!showCommentsSection)}
            >
              Response
            </Button>
          )}
        </Flex>
        {showCommentsSection && (
          <div className="post-comments-wrapper">
            <Flex gap="small" className="write-comment-wrapper">
              <Avatar  icon={<UserOutlined />} size={48} src={userProfile?.profile_picture?.url} />
              <Input
                size="large"
                placeholder="Add a response..."
                value={comment}
                onChange={e => setComment(e.target.value)}
                suffix={
                  <Button
                    primary
                    disabled={isAddCommentToPostLoading}
                    size="small"
                    type="link"
                    onClick={() => handleAddComment(post_id, comment)}
                  >
                    Send
                  </Button>
                }
                prefix={
                  <div style={{ position: "relative" }}>
                    <SmileOutlined
                      color="#00000073"
                      size={24}
                      onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                    />
                    {showEmojiPicker && (
                      <div
                        style={{
                          position: "absolute",
                          top: "40px",
                          left: "0px",
                          zIndex: 10
                        }}
                      >
                        <Picker data={data} onEmojiSelect={addEmoji} />
                      </div>
                    )}
                  </div>
                }
              />
            </Flex>
            {comments?.length > 0 && (
              <Button
                type="link"
                primary
                onClick={() => setShowComments(!showComments)}
                className="show-comments-list-btn"
              >
                View all {comments_count} responses
                {showComments ? <UpOutlined /> : <DownOutlined />}
              </Button>
            )}
            {showComments && (
              <ul className="inner-comments-wrapper">
                {comments?.map(comment => {
                  return (
                    <Flex
                      gap="small"
                      className="single-comment"
                      key={comment.comment_id}
                    >
                      <Avatar
                        size={32}
                        src={comment?.author_profile_picture?.url}
                        icon={<UserOutlined/>}
                      />
                      <Flex gap="small" vertical className="comment-message">
                        <Title level={5}>
                          {comment?.author_name ?? "Anonymous"}
                        </Title>
                        <Text>{comment?.content}</Text>
                      </Flex>
                    </Flex>
                  )
                })}
              </ul>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default forwardRef(PostCard)
