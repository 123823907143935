import React from "react"
import { Flex, Typography } from "antd"

import { useSuggestedCommunitiesQuery } from "../../api/apiSlices/communityApiSlice"
import CommunityListItem from "./components/CommunityListItem"
import "./styles.scss"

const { Title } = Typography

function CommunitiesSuggestions() {
  const { data: suggestedCommunities, isLoading } =
    useSuggestedCommunitiesQuery()

  if (isLoading) return null
  if (!isLoading && suggestedCommunities?.length === 0) return null

  return (
    <div className="suggestions-list-wrapper">
      <Title level={4} className="suggestions-list-title">
        Suggested Communities
      </Title>
      <Flex gap={12} vertical>
        {suggestedCommunities?.map(community => (
          <CommunityListItem
            key={community.community_id}
            id={community.community_id}
            name={community.name}
            membersCount={community.member_count}
            displayImage={community?.display_image?.url}
            visibility={community.visibility}
          />
        ))}
      </Flex>
    </div>
  )
}

export default CommunitiesSuggestions
