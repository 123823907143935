import { Button, Flex, List, Modal, Radio, Spin, Typography } from "antd"
import {
  useGetUserDonationsQuery,
  useGetUserNonRecurringDonationsQuery,
  useRemovePaymentMutation
} from "../../../api/apiSlices/donationApiSlice"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { openNotification } from "../../../util/openNotifications"
import { useState } from "react"
import "./styles.scss"

const { Title, Text } = Typography

const Donations = () => {
  const { data, isLoading, refetch } = useGetUserDonationsQuery(null, {
    refetchOnMountOrArgChange: true
  })
  const { data: allDonations } = useGetUserNonRecurringDonationsQuery(null, {
    refetchOnMountOrArgChange: true
  })
  const [removePayment] = useRemovePaymentMutation()
  const [modal, contextHolder] = Modal.useModal()
  const [mode, setMode] = useState("all")

  console.log("data", data)

  const onRemovePayment = async subscription_id => {
    try {
      await removePayment({ subscription_id }).unwrap()
      refetch()
    } catch (error) {
      openNotification({
        type: "error",
        message: "Failed to remove payment, please try again"
      })
    }
  }

  const handleRemovePayment = id => {
    modal.confirm({
      title: "Remove reoccuring payment",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure you want to delete this payment ?",
      okText: "Remove",
      cancelText: "Cancel",
      okButtonProps: { danger: true },
      onOk: () => onRemovePayment(id),
      centered: true
    })
  }

  return (
    <div className="tabContainer">
      <Spin spinning={isLoading}>
        <Radio.Group
          onChange={event => setMode(event.target.value)}
          value={mode}
          style={{ marginBottom: 8 }}
        >
          <Radio.Button value="all">All Donations</Radio.Button>
          <Radio.Button value="recurring">Recurring</Radio.Button>
        </Radio.Group>
        {mode === "recurring" && (
          <>
            {data && (
              <List
                itemLayout="horizontal"
                dataSource={data}
                renderItem={item => (
                  <List.Item key={item.id} style={{ width: "500px" }}>
                    <Flex
                      style={{ width: "100%" }}
                      justify="space-between"
                      align="center"
                    >
                      <Flex vertical>
                        <Title level={5}>{item.organization_name}</Title>
                        <Text>{item.amount} USD</Text>
                      </Flex>
                      {item.is_recurring && (
                        <Button
                          type="primary"
                          onClick={() =>
                            handleRemovePayment(item.stripe_subscription_id)
                          }
                        >
                          Cancel Donation
                        </Button>
                      )}
                    </Flex>
                  </List.Item>
                )}
              />
            )}
          </>
        )}
        {mode === "all" && (
          <>
            {allDonations && (
              <List
                itemLayout="horizontal"
                dataSource={allDonations}
                renderItem={item => (
                  <List.Item key={item.id} style={{ width: "500px" }}>
                    <Flex
                      style={{ width: "100%" }}
                      justify="space-between"
                      align="center"
                    >
                      <Flex vertical>
                        <Title level={5}>{item.organization_name}</Title>
                        <Text>{item.amount} USD</Text>
                      </Flex>
                    </Flex>
                  </List.Item>
                )}
              />
            )}
          </>
        )}
      </Spin>
      {contextHolder}
    </div>
  )
}

export default Donations
