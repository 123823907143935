import React from 'react'
import { Typography , Button ,Space,Card ,Flex} from 'antd'
import {useState} from 'react'
import { SaveIcon } from '../../../../../assets/rawSvgs';
import { AfterSave } from '../../../../../assets/rawSvgs';



const {Text} =Typography

function ContentGenerated({result,onSave ,handleInsertion}) {

  const [isSaved , setIsSaved]=useState(false);

  const handleSaveClick = () =>{
    setIsSaved(!isSaved);
  }
  return (
   
    
    <div style={{   maxWidth: '100%', wordWrap: 'break-word' }}>
  
     <Space direction="vertical" size="small">
 
      <Text type="secondary">Tell one line story</Text>



         <Text>{result}</Text>
       
        

      <div  style={{ display: 'flex', alignItems: 'center' }}>
      
      
    
     
        <>
       <Flex gap='220px'>
      <Button onClick={() => handleInsertion(result)}>Insert</Button>

      <div  onClick={() => {onSave(); handleSaveClick();}} >
        
           {isSaved ?<AfterSave/>: <SaveIcon/>}
        
        
        </div>
             
      
      </Flex>
        </>
    

     
      
      </div>
      </Space>
      
    </div>
   
  )
}

export default ContentGenerated


