import React, { useEffect, useState } from "react"
import { StreamChat } from "stream-chat"
import {
  Chat,
  Channel,
  ChannelList,
  Window as ChatWindow,
  ChannelHeader,
  MessageList,
  MessageInput,
  Thread
} from "stream-chat-react"
import { useLocation } from "react-router-dom"

import "./layout.modules.css"
import { getQueryParams } from "../../util/getQueryParams"
import axios from "axios"
import { CHANNEL_TYPES } from "../../constants/chat"

const apiKey = "7xg9zsjstaep"

const AdminChat = () => {
  const location = useLocation()

  const params = getQueryParams(location.search)
  const [client, setClient] = useState(null)

  useEffect(() => {
    const init = async () => {
      try {
        const streamToken = await axios.post(
          "https://all-good-people-48318-staging.botics.co/api/v1/public-stream-chat/token/",
          {
            user_id: params?.admin_id
          }
        )
        if (streamToken?.data?.data) {
          const chatClient = StreamChat.getInstance(apiKey)
          await chatClient.connectUser({ id: "admin" }, streamToken?.data?.data)
          setClient(chatClient)
        }
      } catch (error) {
        console.error("Error connecting user to Stream Chat:", error)
      }
    }

    init()
  }, [params])

  if (!client) return <div>Setting up client & connection...</div>
  const filters = { type: CHANNEL_TYPES.MESSAGING, members: { $in: ["admin"] } }
  const sort = [{ last_message_at: -1 }]

  return (
    <div className="ContentContainer">
      <div className="ContentWrap">
        <div className="chat-management-container">
          <Chat client={client}>
            <ChannelList
              filters={filters}
              sort={sort}
              showChannelSearch
              // we will set active channel when routed from diff page
              customActiveChannel={params?.channel_id}
            />

            <Channel>
              <ChatWindow>
                <ChannelHeader />
                <MessageList isReactionEnabled={false} />
                <MessageInput />
              </ChatWindow>
              <Thread />
            </Channel>
          </Chat>
        </div>
      </div>
    </div>
  )
}

export default AdminChat
