import { useEffect, useState } from "react"
import { getUser } from "../api/auth"

export const useIsCommunityAdmin = (communityAdminIds, isLoading = false) => {
  const currentUser = getUser()
  const [isAdmin, setIsAdmin] = useState(false)
  useEffect(() => {
    if (isLoading) return
    if (communityAdminIds?.length && currentUser) {
      const isAdmin = communityAdminIds
        .map(admin => admin.user_id)
        .includes(currentUser.id)
      if (isAdmin) setIsAdmin(true)
      else setIsAdmin(false)
    }
  }, [communityAdminIds, currentUser, isLoading])

  return isAdmin
}
