import React, { useState } from "react"
import {
  Button,
  Typography,
  Flex,
  Space,
  Table,
  Tag,
  Dropdown,
  Image,
  Modal,
  Popover,
  Slider,
  Input,
  Spin
} from "antd"

import { openNotification } from "../../util/openNotifications"

import {
  ActiveIcon,
  BubbleChatIcon,
  CancelledIcon,
  CompletedIcon,
  DeleteIcon3,
  EyeIcon,
  FilterButtonIcon,
  MarkCompletedIcon,
  MoreHorizontalIcon,
  PendingIcon,
  PlusIcon
} from "../../assets/rawSvgs"

import { ExclamationCircleOutlined } from "@ant-design/icons"

import {
  useDeleteJobRequestMutation,
  useGetFlexTimeChatMutation,
  useGetJobRequestsQuery,
  useUpdateJobRequestMutation
} from "../../api/apiSlices/flexTimeApiSlice"

import useRouterNavigation from "../../hooks/useNavigate"

import { Routes } from "../../constants/routes"

import FilterComponent from "./FilterComponent"
import { formatToYYYYMMDD } from "../../util/ConvertDateTime"
import { useDebounce } from "../../hooks/useDebounce"
import { getUser } from "../../api/auth"

const { Title, Text  } = Typography
const { Search } = Input

const AllEmployerJobs = () => {
  const [filters, setFilters] = useState({
    minHourlyRate: null,
    maxHourlyRate: null,
    startDate: null,
    endDate: null,
    status: null
  })
  const [queryFilters, setQueryFilters] = useState({
    budget_max: null,
    budget_min: null,
    start_date: null,
    end_date: null,
    status: null
  })

  const [search, setSearch] = useState("")
  const [loading, setLoading] = useState(false);

  const [modal, contextHolder] = Modal.useModal()

  const { navigateTo } = useRouterNavigation()

  const currentUser = getUser()

  const debouncedSearch = useDebounce(search, 500)

  const {
    data: jobRequestsList,
    isLoading: isJobRequestListLoading,
    refetch: refetchJobRequestList
  } = useGetJobRequestsQuery(
    { ...queryFilters, title: debouncedSearch },
    {
      refetchOnMountOrArgChange: true,
      onSuccess: () => setLoading(false), // Stop loader on success
      onError: () => setLoading(false), // Stop loader on error
    }
  )
  console.log(jobRequestsList);

  
  const [deleteJobRequest, { isLoading: isDeleteRequestLoading }] =
    useDeleteJobRequestMutation()

  const [updateJobRequest, { isLoading: isUpdateRequestLoading }] =
    useUpdateJobRequestMutation()

  const [getFlexTimeChat, { isLoading: isGetFlexTimeChatLoading }] =
    useGetFlexTimeChatMutation()

  const onDeleteJobRequest = jobRequest => {
    modal.confirm({
      title: "Delete Job Request",
      icon: <ExclamationCircleOutlined />,
      content:
        "Are you sure you want to delete this job request? This action cannot be undone.",
      okText: "Delete",
      cancelText: "Cancel",
      okButtonProps: {
        danger: true,
        loading: isDeleteRequestLoading,
        disabled: isDeleteRequestLoading
      },
      onOk: async () => {
        setLoading(true); 
        try {
          await deleteJobRequest(jobRequest?.id).unwrap();
          refetchJobRequestList();
          openNotification({
            type: "success",
            message: "Job Request deleted successfully!"
          });
        } catch (error) {
          openNotification({
            type: "error",
            message: error?.message || "Failed to delete the job request."
          });
        } finally {
          setLoading(false); 
        }
      },
      centered: true
    })
  }

  const onUpdateJobRequest = jobRequest => {
    modal.confirm({
      title: "Mark as completed",
      icon: null,
      content: (
        <>
          <Flex gap={12} vertical>
            <Text style={{ color: "#000000A6" }}>
              Are you sure you want to mark this job as complete?
            </Text>
            <Space>
              <Flex gap={8}>
                <Text strong>Job Title:</Text>
                <Text style={{ color: "#00000073" }}>
                  {jobRequest?.title ?? "-"}
                </Text>
              </Flex>
            </Space>
            <Space>
              <Flex gap={8}>
                <Text strong>Employer:</Text>
                <Text style={{ color: "#00000073" }}>
                  {jobRequest?.employer?.name ?? "-"}
                </Text>
              </Flex>
            </Space>
            <Space>
              <Flex gap={8}>
                <Text strong>Due Date:</Text>
                <Text style={{ color: "#00000073" }}>
                  {jobRequest?.end_date ?? "-"}
                </Text>
              </Flex>
            </Space>
          </Flex>
        </>
      ),
      okText: "Mark as Completed",
      cancelText: "Cancel",
      okButtonProps: {
        loading: isUpdateRequestLoading,
        disabled: isUpdateRequestLoading
      },
      onOk: () => updateJob(jobRequest),
      centered: true
    })
  }

  const updateJob = async data => {
    try {
      await updateJobRequest({
        id: data?.id,
        status: "complete"
      }).unwrap()
      refetchJobRequestList()
      openNotification({
        type: "success",
        message: "Job Request marked as completed"
      })
    } catch (error) {
      openNotification({
        type: "error",
        message:
          error?.message ||
          error?.errors ||
          "Unexpected error occurred. Please try again."
      })
    }
  }

  // const menuItems = data => [
  //   {
  //     label: (
  //       <Space
  //         onClick={() => navigateTo(Routes.JOB_DETAIL.replace(":id", data?.id))}
  //       >
  //         <EyeIcon />
  //         <Text>More Details</Text>
  //       </Space>
  //     ),
  //     key: "0"
  //   },
  //   {
  //     label: (
  //       <Space onClick={() => onUpdateJobRequest(data)}>
  //         <MarkCompletedIcon />
  //         <Text>Mark as completed</Text>
  //       </Space>
  //     ),
  //     key: "1"
  //   },
  //   {
  //     label: (
  //       <Space onClick={() => onDeleteJobRequest(data)}>
  //         <DeleteIcon3 />
  //         <Text>Delete</Text>
  //       </Space>
  //     ),

  //     key: "2"
  //   }
  // ]

  const menuItems = data => {
    const viewDetailsItem = {
      label: (
        <div
          onClick={() => navigateTo(Routes.JOB_DETAIL.replace(":id", data?.id))}
        >
          <Space>
          <EyeIcon />
          <Text>More Details</Text>
          </Space>
        </div>
      ),
      key: "view-details"
    }

    const markAsCompletedItem = {
      label: (
        <div onClick={() => onUpdateJobRequest(data)}>
          <Space>
          <MarkCompletedIcon />
          <Text>Mark as completed</Text>
          </Space>
        </div>
      ),
      key: "mark-complete"
    }

    const deleteItem = {
      label: (
        <div onClick={() => onDeleteJobRequest(data)}>
          <Space>
          <DeleteIcon3 />
          <Text>Delete</Text>
          </Space>
        </div>
      ),
      key: "delete"
    }

    const statusMap = {
      pending: [viewDetailsItem,  deleteItem],
      active: [viewDetailsItem, markAsCompletedItem],
      complete: [viewDetailsItem],
      cancelled: [viewDetailsItem]
    }
    

    return statusMap[data.status] || []
    
  }

  
  const statusStyles = {
    pending: {
      icon: <PendingIcon />,
      borderColor: "#FFD591",
      textColor: "#D46B08",
      backgroundColor: "#FFF7E6",
      label: "Pending"
    },
    active: {
      icon: <ActiveIcon />,
      borderColor: "#91CAFF",
      textColor: "#3DC2DC",
      backgroundColor: "#E6F4FF",
      label: "Active"
    },
    complete: {
      icon: <CompletedIcon />,
      borderColor: "#B7EB8F",
      textColor: "#52C41A",
      backgroundColor: "#F6FFED",
      label: "Completed"
    },
    cancelled: {
      icon: <CancelledIcon />,
      borderColor: "#FFA39E",
      textColor: "#F5222D",
      backgroundColor: "#FFF1F0",
      label: "Cancelled"
    }
  }

  const StatusTag = ({ status }) => {
    const { icon, borderColor, textColor, label, backgroundColor } =
      statusStyles[status] || {}

    return (
      <Tag
        style={{
          borderColor: borderColor,
          color: textColor,
          backgroundColor: backgroundColor,
          borderStyle: "solid",
          borderWidth: "1px"
        }}
      >
        <Space>
          {icon}
          <span>{label}</span>
        </Space>
      </Tag>
    )
  }

  const onFilterApply = () => {
    setLoading(true);
    setQueryFilters({
      hourly_rate_min: filters.minHourlyRate,
      hourly_rate_max: filters.maxHourlyRate,
      start_date: formatToYYYYMMDD(filters.startDate),
      end_date: formatToYYYYMMDD(filters.endDate),
      status: filters.status
    })
    refetchJobRequestList().then(() => {
      setLoading(false); 
    }).catch(() => {
      setLoading(false); 
    });
  }
  

  const sortedJobRequestsList = jobRequestsList
  ? Object.values(jobRequestsList).reverse()  // Convert object to array and reverse it
  : [];



  const handleChat = async data => {
    const payload = {
      recipient_role: currentUser?.role,
      recipient_id: currentUser?.id,
      job_id: data?.id
    }
    try {
      await getFlexTimeChat(payload).unwrap()
      navigateTo(
        `${Routes.CHAT}?channel_id=J${data?.id}-Admin-E${currentUser?.id}`
      )
    } catch (error) {
      openNotification({
        type: "error",
        message:
          error?.message ||
          error?.errors ||
          "Unexpected error occurred. Please try again."
      })
    }
  }

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: text => <a>{text}</a>
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location"
    },
    {
      title: "Requested Worker",
      dataIndex: "number_of_workers",
      key: "number_of_workers"
    },
    {
      title: "Assigned Worker",
      dataIndex: "assigned_worker",
      key: "assigned_worker",
      render: (_, record) => (
        <Text>{record?.assigned_workers?.length ?? "--"}</Text>
      )
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (_, render) => StatusTag(render)
    },
    {
      title: "Duration",
      key: "duration",
      dataIndex: "duration",
      render: (_, record) => (
        <Text>
          {record?.start_date} to {record?.end_date}
        </Text>
      )
    },
    {
      title: "Hourly Rate",
      key: "hourly_rate",
      dataIndex: "hourly_rate",
      render: (_, record) => (
        <Text>
          ${record?.budget_min} - ${record?.budget_max}
        </Text>
      )
    },
    {
      title: "Admin Chat",
      key: "admin_chat",
      dataIndex: "admin_chat",
      render: (_, record) => (
        <Button
          icon={<BubbleChatIcon />}
          disabled={isGetFlexTimeChatLoading}
          onClick={() => handleChat(record)}
        />
      )
    },
    {
      title: "Job Chat",
      key: "job_chat",
      dataIndex: "job_chat",
      render: (_, record) => (
        <Button
          disabled={!record?.initiate_chat}
          icon={<BubbleChatIcon />}
          onClick={() =>
            navigateTo(`${Routes.CHAT}?channel_id=${record?.channel_id}`)
          }
        />
      )
    },
    {
      title: "Actions",
      key: "action",
      align: "center", 

      render: (_, record) => (
        <>
          <Dropdown
            menu={{
              items: menuItems(record)
            }}
            trigger={["click"]}
          >
            <a onClick={e => e.preventDefault()}>
              <MoreHorizontalIcon />
            </a>
          </Dropdown>
        </>
      )
    }
  ]

  return (
    <div>
      <Flex align="center" justify="space-between" className="mb-12">
        <Title level={4} className="mb-1" aria-label="name">
          My Gigs
        </Title>
        <Flex gap={12}>
          <Search
            placeholder="Search"
            // onSearch={() => console.log("searching")}
            className="SearchWrapper"
            allowClear
            value={search}
            onChange={e => setSearch(e.target.value)}
            style={{
              width: 360
            }}
          />
          
          <Popover
            content={
             
             
                <FilterComponent
                  filters={filters}
                  setFilters={setFilters}
                  onFilterApply={onFilterApply}
                  setQueryFilters={setQueryFilters}
                />
             
            
            }
            trigger="click"
            style={{ width: "500px" }}
            overlayInnerStyle={{ padding: "0" }}
          >
            <Button icon={<FilterButtonIcon />}>Filters</Button>
          </Popover>
          
          <Button
            icon={<PlusIcon stroke="#FFFFFF" />}
            onClick={() => navigateTo(Routes.CREATION)}
            type="primary"
          >
            Create New
          </Button>
        </Flex>
      </Flex>
      <Table
        columns={columns}
        dataSource={sortedJobRequestsList}
        loading={isJobRequestListLoading || loading }
      />
      {contextHolder}
    </div>
  )
}

export default AllEmployerJobs
