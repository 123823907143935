// src/api/endpoints/exampleEndpoint.js
import {
  LOGIN_URL,
  FACEBOOK_SSO_LOGIN_URL,
  GOOGLE_SSO_LOGIN_URL,
  VERIFY_OTP_URL,
  RESET_PASSWORD_URL,
  FORGOT_PASSWORD_URL,
  REGISTER_URL,
  RESEND_OTP_URL,
  ASSIGN_ROLE_URL
} from "../../constants/endpoints"

import { apiSlice } from "../apiSlice"

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    login: builder.mutation({
      query: data => ({
        url: LOGIN_URL,
        method: "POST",
        body: data
      })
    }),
    facebookSSOLogin: builder.mutation({
      query: data => ({
        url: FACEBOOK_SSO_LOGIN_URL,
        method: "POST",
        body: data
      })
    }),

    googleSSOLogin: builder.mutation({
      query: data => ({
        url: GOOGLE_SSO_LOGIN_URL,
        method: "POST",
        body: data
      })
    }),
    register: builder.mutation({
      query: data => ({
        url: REGISTER_URL,
        method: "POST",
        body: data
      })
    }),
    verifyOtp: builder.mutation({
      query: data => ({
        url: VERIFY_OTP_URL,
        method: "POST",
        body: data
      })
    }),
    resendOtp: builder.mutation({
      query: data => ({
        url: RESEND_OTP_URL,
        method: "POST",
        body: data
      })
    }),
    forgotPassword: builder.mutation({
      query: data => ({
        url: FORGOT_PASSWORD_URL,
        method: "POST",
        body: data
      })
    }),
    resetPassword: builder.mutation({
      query: data => ({
        url: RESET_PASSWORD_URL.replace(":id", data.id).replace(
          ":token",
          data.token
        ),
        method: "POST",
        body: data
      })
    }),
    assignRole: builder.mutation({
      query: data => ({
        url: ASSIGN_ROLE_URL,
        method: "POST",
        body: data
      })
    })
  }),
  overrideExisting: false
})

export const {
  useLoginMutation,
  useFacebookSSOLoginMutation,
  useGoogleSSOLoginMutation,
  useRegisterMutation,
  useVerifyOtpMutation,
  useResendOtpMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useAssignRoleMutation
} = authApiSlice
