import { useFormik } from "formik"
import CustomInput from "../../../components/customInput/CustomInput"
import CustomTextArea from "../../../components/customInput/CustomTextArea"
import Header from "./Header"
import "./styles.scss"
import {
  useGetWorkerProfileInfoQuery,
  useUpdateWorkerProfileInfoMutation
} from "../../../api/apiSlices/workerApiSlice"
import { setOnboardingStatus } from "./onboardingStatus"
import * as Yup from "yup"
import { Button, Flex, Spin } from "antd"
import { openNotification } from "../../../util/openNotifications"
import { useEffect } from "react"

export const profileValidationSchema = Yup.object({
  professional_role: Yup.string().required("Role is required"),
  description: Yup.string()
    .required("Description is required")
    .min(50, "Description should be atleast 50 characters long")
})

const DESCRIPTION =
  "Add your title and a few lines about yourself to personalize your experience. What work do you best? You can always edit later; just make sure you proofread now."

const CreateProfile = ({
  onNextClick = null,
  wrapperClassName = "",
  skipStep,
  isEditMode = null
}) => {
  const { data, isLoading, refetch } = useGetWorkerProfileInfoQuery()
  const [updateProfileInfo] = useUpdateWorkerProfileInfoMutation()

  const { values, errors, touched, handleSubmit, handleBlur, setValues } =
    useFormik({
      initialValues: {
        professional_role: "",
        description: ""
      },
      validationSchema: profileValidationSchema,
      onSubmit: async values => {
        try {
          await updateProfileInfo(values).unwrap()
          refetch()
          setOnboardingStatus("step1", true)
          if (onNextClick) onNextClick()
        } catch (error) {
          openNotification({
            type: "error",
            message: "Failed to update info at this time"
          })
        }
      }
    })

  useEffect(() => {
    if (!isLoading && data) {
      setValues({
        professional_role: data?.profile.professional_role || "",
        description: data?.profile.description || ""
      })
    }
  }, [data])

  return (
    <>
      <div className={wrapperClassName}>
        <Spin spinning={isLoading}>
          <Header title="Tell us about yourself" description={DESCRIPTION} />
          <form>
            <CustomInput
              name="professional_role"
              label="Your Role"
              inputwd="w-100 mb-3"
              placeholder="Enter role here"
              className="mb-3"
              description="Enter any role that describes you—this could be a professional title (e.g., 'Engineer') or a personal role (e.g., 'Mother')."
              value={values?.professional_role}
              onChange={e =>
                setValues({ ...values, professional_role: e.target.value })
              }
              errorMessage={
                touched["professional_role"] && errors?.professional_role
              }
              onBlur={handleBlur}
              inputProps={{
                status:
                  touched["professional_role"] && errors?.professional_role
                    ? "error"
                    : undefined
              }}
            />
            <CustomTextArea
              name="description"
              label="Description"
              inputwd="w-100 mb-3"
              placeholder="Enter your top skills, experience, and interests."
              className="mb-3"
              description="Share your story with us! Whether it's a life lesson, a major event, or something you've learned along the way, we'd love to hear from you."
              value={values?.description}
              onChange={e =>
                setValues({ ...values, description: e.target.value })
              }
              errorMessage={touched["description"] && errors?.description}
              onBlur={handleBlur}
              inputProps={{
                status:
                  touched["description"] && errors?.description
                    ? "error"
                    : undefined
              }}
            />
          </form>
        </Spin>
      </div>
      {onNextClick && (
        <Flex gap="8px" justify="space-between">
          {!isEditMode && (
            <Button onClick={skipStep} type="text">
              Skip this step
            </Button>
          )}
          <Button type="primary" onClick={handleSubmit}>
            Next
          </Button>
        </Flex>
      )}
    </>
  )
}

export default CreateProfile
