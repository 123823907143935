import React, { useState } from "react"
import { Typography, Button, Flex, Modal, Spin } from "antd"
import Header from "./Header"
import { PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons"
import EducationInputModal from "./EducationInputModal"
import {
  useGetWorkerProfileInfoQuery,
  useDeleteWorkerEducationMutation
} from "../../../api/apiSlices/workerApiSlice"
import EducationExperienceCard from "./EducationExperienceCard"
import { openNotification } from "../../../util/openNotifications"
import "./styles.scss"

function Education({
  wrapperClassName = "",
  onNextClick = null,
  onPreviousClick = null,
  skipStep,
  isEditMode = false
}) {
  const [isOpenEducationInputModal, setIsOpenEducationInputModal] =
    useState(false)
  const [modal, contextHolder] = Modal.useModal()

  const { data = [], isLoading, refetch } = useGetWorkerProfileInfoQuery()
  //const { data=[], isLoading, refetch } = useGetWorkerEducationInfoQuery(33)
  console.log("data", data)
  const [deleteEducationExperience] = useDeleteWorkerEducationMutation()
  const [workExpToEdit, setWorkExpToEdit] = useState()

  const onCloseExpInputModal = (success = false) => {
    if (success) {
      refetch()
    }
    if (workExpToEdit) setWorkExpToEdit(null)
    setIsOpenEducationInputModal(false)
  }
  const onEditEdu = workExp => {
    setWorkExpToEdit(workExp)
    setIsOpenEducationInputModal(true)
  }
  const onDeleteEdu = workExp => {
    modal.confirm({
      title: "Delete Work Expereince",
      icon: <ExclamationCircleOutlined />,
      content:
        "Are you sure you want to delete this experience? This action cannot be undone.",
      okText: "Delete",
      cancelText: "Cancel",
      okButtonProps: { danger: true },
      onOk: () =>
        deleteEducationExperience(workExp.id).then(() => {
          refetch()
          openNotification({
            type: "success",
            message: "Education deleted successfully!"
          })
        }),
      centered: true
    })
  }

  const Description =
    "Tell us about your educational background! Share the schools you ve attended, degrees earned, or any courses or certifications that have shaped your journey."
  return (
    <>
      <Spin spinning={isLoading}>
        <div className="onboardingSteps">
          <Header title="Add education" description={Description} />
          <div className="educationContainer">
            <div
              className="addEducation"
              onClick={() => setIsOpenEducationInputModal(true)}
            >
              <PlusOutlined />
              <h5>Add Education </h5>
            </div>

            <div className="workExpList">
              {data?.profile?.education &&
              Array.isArray(data.profile.education) ? (
                data?.profile?.education.map(workExp => (
                  <EducationExperienceCard
                    workExp={workExp}
                    onDeleteEdu={onDeleteEdu}
                    onEditEdu={onEditEdu}
                  />
                ))
              ) : (
                <p>No education records available.</p>
              )}
            </div>
          </div>
        </div>

        {isOpenEducationInputModal && (
          <EducationInputModal
            onClose={onCloseExpInputModal}
            initialData={workExpToEdit ? workExpToEdit : null}
          />
        )}
        {contextHolder}
        <Flex justify="space-between">
          {!isEditMode && (
            <Button type="text" onClick={skipStep}>
              Skip this step
            </Button>
          )}

          <Flex gap="12px">
            <Button onClick={onPreviousClick}>Back</Button>
            <Button type="primary" onClick={onNextClick}>
              Next
            </Button>
          </Flex>
        </Flex>
      </Spin>
    </>
  )
}

export default Education
