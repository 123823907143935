import { useEffect, useState } from 'react';
import { useMyJoinedCommunitiesQuery } from '../api/apiSlices/communityApiSlice';

export const useIsPartOfCommunity = (communityId) => {
  const [isJoined, setIsJoined] = useState(false);
  const {
    data: joinedCommunities,
    isLoading,
    isFetching,
    refetch,
  } = useMyJoinedCommunitiesQuery(null, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (
      isLoading ||
      isFetching ||
      !joinedCommunities?.results ||
      !communityId
    ) {
      setIsJoined(null);
    } else {
      const isPartOf = joinedCommunities.results
        .map((community) => community.community_id)
        .includes(parseInt(communityId));

      setIsJoined(isPartOf);
    }
  }, [joinedCommunities, communityId, isLoading, isFetching]);

  return [isJoined, refetch, isLoading, isFetching];
};
