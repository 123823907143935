import React from "react"
import { Avatar, Flex, Typography } from "antd"
import { UsergroupDeleteOutlined  } from "@ant-design/icons"

import { useNavigate } from "react-router-dom"
import "./styles.scss"

const { Text, Title } = Typography

function CommunityListItem({
  id,
  name = "",
  visibility = "",
  membersCount = 0,
  displayImage = null
}) {
  const navigate = useNavigate()
  return (
    <Flex
      gap="middle"
      justify="space-between"
      align="center"
      onClick={() => {
        navigate(`/community/${id}`)
      }}
      style={{ cursor: "pointer" }}
    >
      <Flex gap="middle" align="center">
        <Avatar
          size={40}
          src={displayImage}
          className="community-list-item-avatar"
          icon={<UsergroupDeleteOutlined />}
        />
        <Flex vertical>
          <Title level={5}>{name}</Title>
          <Flex gap={8} align="center">
            <Text className="community-details">{`${membersCount} members`}</Text>
            <div className="dot dot_small" />
            <Text className="community-details capitalize">{visibility}</Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default CommunityListItem
