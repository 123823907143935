
import * as Yup from 'yup';


export const jobCreationSchema = Yup.object({
  professional_role: Yup.string()
    .required('Required')
    .max(30, 'Cannot be more than 30 characters'),
  description: Yup.string()
    .min(50, 'Minimum 50 characters required')
    .required('Required'),
  skills: Yup.array()
    .min(1, 'At least one skill is required'),

  rateMin: Yup.number()
    .min(20, 'Minimum rate must be greater than 20 or equal to 20')
    .max(150,"Maximum rate must be less than 150 or equal to 150")
    .required('Required')
    .test(
      'is-decimal',
      'Rate must be a number with up to two decimal places',
      (value) => /^\d+(\.\d{1,2})?$/.test(value?.toString())
    ),
    
  rateMax: Yup.number()
    .min(Yup.ref('rateMin'), 'Max rate should be greater than or equal to Min rate')
    .max(150, 'Max rate should not exceed 150')
    .required('Required')
    .test(
      'is-decimal',
      'Rate must be a number with up to two decimal places',
      (value) => {
        
        if (value === undefined || value === null || value === '') {
          return true;
        }
        return /^\d+(\.\d{1,2})?$/.test(value?.toString());
      }
    ),
    
  workersNeeded: Yup.number()
    .min(1, 'At least 1 worker is required')
    .max(1000, 'A maximum of 1000 workers can be assigned')
    .required('Required').integer('Please enter a whole number for the number of workers (decimals are not allowed)')
    ,
  dateRange: Yup.array()
    .min(2, 'Please select both start and end dates')
    .required('Job duration is required'),
  location: Yup.string()
    .required('Location is required'),
  notes: Yup.string()
    .min(100, 'Description should be at least 100 characters long')
    
    
});
