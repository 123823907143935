import React, { useState } from "react"
import { Button, Empty, Flex, Typography } from "antd"

import { PlusOutlined } from "@ant-design/icons"
import CreateCommunityModal from "./CreateCommunityModal"
import { useMyManagedCommunitesQuery } from "../../api/apiSlices/communityApiSlice"
import CommunityListItem from "./components/CommunityListItem"
import "./styles.scss"

const { Title } = Typography

function CommunitiesManageBanner() {
  const {
    data: userManagedCommunities,
    isLoading,
    refetch
  } = useMyManagedCommunitesQuery()

  const [isCreateCommunityModal, setIsCreateCommunityModal] = useState(false)

  return (
    <div className="suggestions-list-wrapper">
      <Title level={4} className="suggestions-list-title">
        Communities I Manage
      </Title>
      <Flex gap={12} vertical>
        {userManagedCommunities?.results?.map(community => (
          <CommunityListItem
            key={community.community_id}
            id={community.community_id}
            name={community.name}
            membersCount={community.member_count}
            displayImage={community?.display_image?.url}
            visibility={community.visibility}
          />
        ))}
        {!isLoading && userManagedCommunities?.results?.length === 0 && (
          <Flex justify="center" align="center" vertical>
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Communities managed by you"
            />
          </Flex>
        )}
      </Flex>

      <Button
        className="create-community"
        icon={<PlusOutlined />}
        onClick={() => setIsCreateCommunityModal(true)}
      >
        Create New Community
      </Button>
      {isCreateCommunityModal && (
        <CreateCommunityModal
          onCancel={() => setIsCreateCommunityModal(false)}
          refetch={refetch}
        />
      )}
    </div>
  )
}

export default CommunitiesManageBanner
