import React , {useState }from 'react'
import { Modal , Typography , Flex} from 'antd'
import CustomSelect from '../../components/customSelect/CustomSelect'
import CustomTextArea from '../../components/customInput/CustomTextArea'
import Header from '../user-experience/components/Header'
import * as Yup from 'yup';
import { useFormik } from 'formik'
import { useUpdateUserInfoMutation } from "../../api/apiSlices/InformationModalApiSlice";

const {Title}= Typography;

// 1-validation schema bnao iska  --------done
   //1.2-formik ka hook use kro ----done
//2-intial values do -------done
//3- call the api in formik ----------done
//4-wrap it in form and call onsubmit and formiks formik.handlesubmit
//5-value and onchange call
//6-formik error handling krni hai 


const validationSchema = Yup.object().shape({
  influenced_religion: Yup.string().required("Please select it's required "),
  political_affection:Yup.string().required("Please select it's required"),
  local_politics:Yup.string().required("Please select it's required"),
  social_and_political_issues:Yup.string().required("Please select it's required")
})



function Step3() {
   
  const [isModalOpen, setIsModalOpen] = useState(true); 
  const [updateUserInfo, { isLoading, error }] = useUpdateUserInfoMutation();


  const formik = useFormik({
    initialValues:{
      influenced_religion:"",
      political_affection:"",
      local_politics:"",
      social_and_political_issues:"",
      validationSchema:validationSchema,
      onSubmit :(values)=>{
        updateUserInfo(values)  
      .unwrap() 
      .then((response) => {
        console.log("Response from API:", response);
      })
      .catch((error) => {
        console.error("Error during API call:", error);
      });

      }


      
    }
  })

 
 
  const handleClose = () => {
      setIsModalOpen(false);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };


    const title="We’d Love to Learn About Your Values ✨"
    const description="Let us know about the values and beliefs that guide your actions and decision"
  return (
    <div> 
      <Modal open={true}
      title={
        <span className="custom-text-style " style={{ color: '#00000073'}}>
          Let’s Get To Know You More
        </span>}
        onClose={handleClose} 
        onOk={handleOk} onCancel={handleCancel}
        > 
        
       
        <Flex gap="12px" vertical >
        <div>
            <Title className='text-lg-semibold ' level={4}>We’d Love to Learn About Your Values ✨</Title>
            <Header  description={description}></Header>
        </div>

        <div>
            <CustomSelect  
            label="Is there a religion that has influenced you?"
            placeholder="Select" 
            options={[{label:"Past" , value:"Past"},
                      {label:"Present" , value:"Present"},
                      {label:"Neutral" , value:"Neutral"}
            ]}></CustomSelect>
        </div>
        <div>
            <CustomSelect  
            label="What’s been your political affiliation?"
            placeholder="Select"
            options={[{label:"Past" , value:"Past"},
              {label:"Present" , value:"Present"},
              {label:"Neutral" , value:"Neutral"}
    ]}
            ></CustomSelect>
        </div>
        <div>
            <CustomSelect  
            label="How involved are you in local politics?"
            placeholder="Select"
            options={[{label:"Maiores deserunt quaerat sed tenetur tk." , value:"Maiores"},
              {label:"Fugit ullam voluptate sunt cum, non cod." , value:"Fugit ulla"},
              {label:"Est quae quae labore, labore asperioref." , value:"Est q"}]}></CustomSelect>
        </div>
        <div>
            <CustomTextArea 
            label="What are your views on some key social and political issues?"
            placeholder="Enter Here"></CustomTextArea>
        </div>

        </Flex>
      </Modal>
    </div>
  )
}

export default Step3
