import React from 'react';
import { Input, Button } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { useState } from 'react';



const SearchBar = ({onSearch}) => {

  const [searchValue, setSearchValue] = useState('');

  const handleSearchClick = () =>
  {
    if(onSearch)
    {
        onSearch(searchValue);
    }
  }
   
  

  return (
    <div>
       <Input
      placeholder="Tell us too"
      value={searchValue}
      onChange={(e) => setSearchValue(e.target.value)}
      suffix={
        <SendOutlined
          style={{ cursor: 'pointer' }}
          onClick={handleSearchClick}
        />
      }
      size="large"
      style={{width:"350px" , height:"40px"}}
      
    />
    </div>
  );
};

export default SearchBar;
