import { useState } from "react"
import { useFormik } from "formik"
import { Checkbox, Flex, Image, Input, Upload } from "antd"
import { PlusOutlined } from "@ant-design/icons"

import SettingsSection from "../components/settings-section/SettingsSection"
import GoToButton from "../../../components/go-to-button"
import { getBase64 } from "../../../util/GetBase64"
import { openNotification } from "../../../util/openNotifications"
import { feedbackSupportValidationSchema } from "../../../schemas/feedbackSupportValidationSchema"

import {
  useAddMediaMutation,
  useDeleteMediaMutation,
  useSubmitFeedbackMutation
} from "../../../api/apiSlices/settingsApiSlice"

import CustomModal from "../../../components/Modal/CustomModal"
import CustomSelect from "../../../components/customSelect/CustomSelect"

import "./styles.scss"

const { TextArea } = Input

const FeedbackAndSupport = () => {
  const [show, setShow] = useState()
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState("")
  const [fileList, setFileList] = useState([])
  const [open, setOpen] = useState(false)

  const feedbackForm = {
    category: "",
    description: "",
    object_keys: [],
    follow_up: false
  }

  const [
    submitFeedback,
    { isLoading: isSubmitfeedbackLoading, error: submitFeedbackError }
  ] = useSubmitFeedbackMutation()

  const [addMedia, { isLoading: isAddMediaLoading, error: addMediaError }] =
    useAddMediaMutation()

  const [
    deleteMedia,
    { isLoading: isDeleteMediaLoading, error: deleteMediaError }
  ] = useDeleteMediaMutation()

  const options = [
    {
      value: "problem",
      label: "Report a Problem"
    },
    {
      value: "profile",
      label: "Profile"
    },
    {
      value: "privacy",
      label: "Privacy"
    },
    {
      value: "feed",
      label: "Feed"
    }
  ]

  const {
    values,
    setValues,
    errors,
    touched,
    handleChange,
    handleSubmit,
    resetForm
  } = useFormik({
    initialValues: feedbackForm,
    validationSchema: feedbackSupportValidationSchema,
    onSubmit: async values => {
      try {
        await submitFeedback(values).unwrap()
        openNotification({
          type: "success",
          message: "Feedback submitted successfully."
        })
        resetForm()
        setFileList([])
        setOpen(false)
      } catch (error) {
        openNotification({
          type: "error",
          message: error?.message
        })
      }
    }
  })

  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewImage(file.url || file.preview)
    setPreviewOpen(true)
  }

  const handleFileChange = ({ fileList: newFileList }) => {
    setFileList(newFileList)
  }

  const handleRemove = async file => {
    setValues({
      ...values,
      object_keys: values.object_keys.filter(
        item => item !== file.response.object_key
      )
    })

    await deleteMedia({ object_key: file.response.object_key })
  }

  const handleUpload = async ({ file, onSuccess, onError }) => {
    const formData = new FormData()
    formData.append("file", file)
    try {
      const data = await addMedia(formData)
      setValues({
        ...values,
        object_keys: [...values.object_keys, data?.data?.object_key]
      })
      onSuccess(data.data, file)
      setFileList(prevList =>
        prevList.map(item =>
          item.uid === file.uid
            ? { ...item, thumbUrl: data?.data?.url, status: "done" }
            : item
        )
      )
    } catch (error) {
      onError(error)
      setFileList(prevList =>
        prevList.map(item =>
          item.uid === file.uid ? { ...item, status: "error" } : item
        )
      )
    }
  }

  const showModal = () => {
    setOpen(true)
  }
  const handleCancel = () => {
    resetForm()
    setFileList([])
    setOpen(false)
  }

  return (
    <div className="tabContainer">
      <SettingsSection
        title="Privacy FAQ"
        description="Access frequently asked questions about privacy settings."
      >
        <div className="actionSection">
          <div className="deleteBtnWrapper">
            <GoToButton title="View FAQs" onClick={() => setShow(true)} />
          </div>
        </div>
      </SettingsSection>
      <SettingsSection
        title="Provide Feedback"
        description="Give suggestions or report problems."
      >
        <div className="actionSection">
          <div className="deleteBtnWrapper">
            <CustomModal
              ModalTrigger={
                <GoToButton
                  title="Give Feedback"
                  onClick={() => setShow(true)}
                />
              }
              showModal={showModal}
              handleCancel={handleCancel}
              handleOk={handleSubmit}
              title="Feedback"
              primeBtnText="Submit"
              modalBodyClass="feedbackModal"
              open={open}
              isDisabled={
                isSubmitfeedbackLoading ||
                isAddMediaLoading ||
                isDeleteMediaLoading
              }
              ModalBody={
                <>
                  <CustomSelect
                    label="How can we improve?"
                    placeholder="Choose an area"
                    value={values.category}
                    options={options}
                    handleChange={value =>
                      setValues({ ...values, category: value })
                    }
                  />
                  {errors.category && (
                    <p className="Input_errorMessage">{errors.category}</p>
                  )}
                  <div>
                    <label htmlFor="" className="label500">
                      Description
                    </label>
                    <TextArea
                      value={values.description}
                      onChange={e =>
                        setValues({ ...values, description: e.target.value })
                      }
                      rows={4}
                      placeholder="Please include as much information as possible... "
                    />
                    {errors.description && (
                      <p className="Input_errorMessage">{errors.description}</p>
                    )}
                  </div>
                  <Upload
                    customRequest={handleUpload}
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleFileChange}
                    onRemove={handleRemove}
                  >
                    <Flex vertical gap="small" align="center">
                      <PlusOutlined />
                      Upload
                    </Flex>
                  </Upload>
                  {previewImage && (
                    <Image
                      wrapperStyle={{
                        display: "none"
                      }}
                      preview={{
                        visible: previewOpen,
                        onVisibleChange: visible => setPreviewOpen(visible),
                        afterOpenChange: visible =>
                          !visible && setPreviewImage("")
                      }}
                      src={previewImage}
                    />
                  )}
                  <Checkbox
                    onChange={e =>
                      setValues({ ...values, follow_up: e.target.checked })
                    }
                    checked={values.follow_up}
                    className="TopCheckboxWrap"
                  >
                    I would like to receive follow-up communication regarding my
                    feedback.
                  </Checkbox>
                </>
              }
            />
          </div>
        </div>
      </SettingsSection>
    </div>
  )
}

export default FeedbackAndSupport
