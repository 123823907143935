
let onboardingStatus = {
    step1: false,
    step2: false,
    step3: false,
    step4: false,
  };
  
  export const setOnboardingStatus = (step, value) => {
    onboardingStatus[step] = value;
  };
  
  export const getOnboardingStatus = () => {
    return onboardingStatus;
  };
  
  export const isOnboardingCompleted = () => {
    return Object.values(onboardingStatus).every((status) => status === true);
  };
  