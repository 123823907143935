import React from 'react'
import { Typography ,Card,Flex,Tag ,Divider } from 'antd'
import './styles.module.scss';
import AntDesignTagsComponenet from '../../../../../components/AntDesignTags/AntdesignTags';
import AntDesignCheckable from '../../../../../components/AntDesignTags/AntDesignCheckable';

import { Input, Button } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { useState } from 'react';
import SearchBar from './InputBar';

const { Text, Link  } = Typography;



const tagsData = ['Movies', 'Books', 'Movies', 'Books', 'Music', 'Sports'];


function ToneSelector(backgroundColor ,{handleSearch}) {

  const [selectedTagIndex, setSelectedTagIndex] = useState(null);

  const handleTagClick = (index) => {
    setSelectedTagIndex(index === selectedTagIndex ? null : index); 
  };

  return (
    <>
    
    
   
      
      <div
      size="small"
      style={backgroundColor}
      
    
    >
   <Flex style={{marginBottom:'8px'}}>
   <Text type="secondary"  style={{ fontWeight: '400' }}>Pick a tone of your choice</Text>
   </Flex>
   <Flex gap="8px " wrap >

     {tagsData.map((tag, index) => (
          <AntDesignCheckable
            key={tag} 
            tagText={tag}
            isSelected={selectedTagIndex === index} 
            onClick={() => handleTagClick(index)} 
          />
        ))}
     
    </Flex>
   
    </div>
     
    </>
  )
}

export default ToneSelector
