import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  isLoading: false,
  error: "",
  isOpenCreatePost: false,
  reloadFeed: false
}

const slice = createSlice({
  name: "socialMedia",
  initialState,
  reducers: {
    setIsOpenCreatePost(state, action) {
      state.isOpenCreatePost = action.payload
    },
    toggleReloadFeed(state, action) {
      state.reloadFeed = action.payload
    }
  }
})

export const { setIsOpenCreatePost, toggleReloadFeed } = slice.actions
// Reducer
export default slice.reducer
