import axios from 'axios';
import {
  getJWTToken,
  getRefreshToken,
  removeJWTToken,
  removeRefreshToken,
  setJWTToken,
  setRefreshToken,
} from './auth';
import { openNotification } from '../util/openNotifications';

const DEFAULT_ERROR_OBJECT = {
  detail: 'Unexpected error occurred. Please try again.',
};
export const baseURL =
  'https://all-good-people-48318-staging.azurewebsites.net';

const main_api = axios.create({
  baseURL: baseURL,
  // headers: {
  //   "ngrok-skip-browser-warning": true
  // }
});

main_api.interceptors.request.use(
  (config) => {
    const jwt_token = getJWTToken();
    if (jwt_token) {
      config.headers.Authorization = `Bearer ${jwt_token}`;
    } else {
      delete config.headers.Authorization;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

const errorResponseInterceptor = (error) => {
  if (error.response) {
    if (error.response.status === 401) {
      const refreshToken = getRefreshToken();
      const originalRequest = error.config;
      if (refreshToken && !originalRequest._retry) {
        originalRequest._retry = true;
        return axios
          .post(`${baseURL}/api/v1/token/refresh/`, { refresh: refreshToken })
          .then((response) => {
            const { access, refresh } = response.data;
            setJWTToken(access);
            setRefreshToken(refresh);
            originalRequest.headers['Authorization'] = `Bearer ${access}`;
            return axios(originalRequest);
          })
          .catch((error) => {
            removeJWTToken();
            removeRefreshToken();
            openNotification({
              type: 'error',
              message: 'Session Expired!',
            });
            window.location.reload();
            return Promise.reject(error);
          });
      } else {
        removeJWTToken();
        removeRefreshToken();
        openNotification({
          type: 'error',
          message: 'Session Expired!',
        });
        window.location.reload();
      }
    } else if (
      !error.response.data ||
      typeof error.response.data === 'string'
    ) {
      error.response.data = DEFAULT_ERROR_OBJECT;
    }
  } else {
    error.response = {
      data: DEFAULT_ERROR_OBJECT,
    };
  }
  return Promise.reject(error);
};

main_api.interceptors.response.use((response) => {
  return response;
}, errorResponseInterceptor);

export { main_api };
