export const LOGIN_URL = "/api/v1/login/"
export const FACEBOOK_SSO_LOGIN_URL = "/api/v1/social-auth/facebook/login/"
export const GOOGLE_SSO_LOGIN_URL = "/api/v1/social-auth/google/login/"
export const REGISTER_URL = "/api/v1/signup/"
export const ASSIGN_ROLE_URL = "/api/v1/assign-role/"
export const VERIFY_OTP_URL = "/api/v1/verify-otp/"
export const RESEND_OTP_URL = "/api/v1/resend-otp/"
export const FORGOT_PASSWORD_URL = "/api/v1/password-reset/"
export const RESET_PASSWORD_URL = "/api/v1/password-reset-confirm/:id/:token/"
export const GET_PRIVACY_CONSENT_URL = "/api/v1/consent/"
export const SET_PRIVACY_CONSENT_URL = "/api/v1/consent/"
export const DELETE_ACCOUNT_URL = "/api/v1/account/delete-account/"
export const CHECK_PRIVACY_POLICY_CONSENT_STATUS =
  "/api/v1/policy/check-consent/"
export const FETCH_PRIVACY_POLICY_URL = "/api/v1/get-privacy-policy/"
export const ACCEPT_PRIVACY_POLICY_CHANGES_URL =
  "/api/v1/policy/accept-consent/"
export const FEEDBACK_URL = "/api/v1/feedback/"
export const ADD_MEDIA_URL = "/api/v1/file-upload/"
export const GET_VISIBILITY_SETTINGS = "/api/v1/privacy-settings/"
export const UPDATE_VISIBILITY_SETTINGS = "/api/v1/privacy-settings/"
export const DELETE_MEDIA_URL = "/api/v1/file-delete/"
export const GET_SOCIAL_FEED = "/api/v1/social-media/feed/"
export const GET_USER_CONNECTIONS = "/api/v1/social-media/user-connections/"
export const CREATE_SOCIAL_MEDIA_POST = "/api/v1/social-media/posts/"
export const DELETE_USER_CONNECTION =
  "/api/v1/social-media/user-connections/:id/"
export const GET_USER_SUGGESTIONS =
  "/api/v1/social-media/user-connections/suggestions/"
export const ADD_USER_CONNECTIONS = "/api/v1/social-media/user-connections/"
export const TOGGLE_POST_LIKE = "/api/v1/social-media/posts/:postId/like/"
export const ADD_POST_COMMENT = "/api/v1/social-media/posts/:postId/comments/"
export const GET_WORKER_INFO_URL = "/api/v1/get-worker-profile-information/"
export const SAVE_WORKER_PROFILE_INFO = "/api/v1/create-worker-profile/"
export const SAVE_WORKER_EXPERIENCE = "/api/v1/add-work-experience/"

export const GET_WORKER_EDUCATION = "/api/v1/user-education/:id"
export const SAVE_WORKER_EDUCATION = "/api/v1/user-education/"
export const EDIT_WORKER_EDUCATION = "/api/v1/user-education/:id/"
export const DELETE_WORK_EDUCATION = "/api/v1/user-education/:id/"

export const DELETE_WORK_EXPERIENCE = "/api/v1/add-work-experience/:id/"
export const ADD_WORKER_SKILL = "/api/v1/add-skill/"
export const ADD_CUSTOM_AVAILABILITY = "/api/v1/set-custom-availability/"
export const ADD_RECURRING_AVAILABILITY = "/api/v1/set-weekly-availability/"
export const FINISH_ONBOARDING = "/api/v1/set-worker-onboarding-flag-true/"
export const GET_WORKER_PROFILE = "/api/v1/get-worker-profile-information/"
export const GET_PRIVACY_POLICY = "/api/v1/get-privacy-policy/"
export const GET_TERMS_AND_CONDITIONS = "/api/v1/get-terms-conditions/"
export const GET_USER_POSTS = "/api/v1/social-media/posts/"
export const GET_COUNTRIES = "/api/v1/countries/"
export const EDIT_WORKER_PROFILE = "/api/v1/edit-worker-profile/"
export const CREATE_COMMUNITY = "/api/v1/social-media/communities/"
export const UPDATE_COMMUNITY = "/api/v1/social-media/communities/:id/"
export const GET_COMMUNITY_DETAILS = "/api/v1/social-media/communities/:id/"
export const DELETE_COMMUNITY = "/api/v1/social-media/communities/:id/"
export const MY_CREATED_COMMUNITIES =
  "/api/v1/social-media/communities/created/"
export const MY_JOINED_COMMUNITIES = "/api/v1/social-media/communities/joined/"
export const JOINED_AND_CREATED_COMMUNITIES =
  "/api/v1/social-media/communities/created-joined/"
export const SUGGESTED_COMMUNITIES =
  "/api/v1/social-media/communities/suggestions/"
export const JOIN_COMMUNITY =
  "/api/v1/social-media/communities/:id/join-community/"
export const VIEW_COMMUNITY_INVITATIONS =
  "/api/v1/social-media/communities/:id/invitations/"
export const ACCEPT_COMMUNITY_JOIN_REQUEST =
  "/api/v1/social-media/community-invitations/:invite-id/accept/"
export const REJECT_COMMUNITY_JOIN_REQUEST =
  "/api/v1/social-media/communities/:id/remove-invitation/"
export const COMMUNITY_FEED = "/api/v1/social-media/communities/:id/posts/"
export const REMOVE_COMMUNITY_POST = "/api/v1/social-media/posts/:id/"
export const CREATE_GIG = "/api/v1/job-requests/"
export const GET_JOB_REQUEST = "/api/v1/job-requests/"
export const DELETE_JOB_REQUEST = "/api/v1/job-requests/:id/"
export const UPDATE_JOB_REQUEST = "/api/v1/job-requests/:id/"
export const GET_JOB_REQUEST_DETAIL = "/api/v1/job-requests/:id/"
export const GET_SOCIAL_MEDIA_PROFILE =
  "/api/v1/social-media/socialmediaprofile/:id/"

export const SEARCH_COMMUNITIES = "/api/v1/social-media/communities/"

export const COMMUNITIES_FEED =
  "/api/v1/social-media/posts/joined-communities-feed/"
export const UPDATE_PROFILE = "/api/v1/social-media/socialmediaprofile/change/"
export const LEAVE_COMMUNITY =
  "/api/v1/social-media/communities/:id/leave-community/"
export const COMMUNITY_MEMBERS = "/api/v1/social-media/communities/:id/members/"
export const GET_NOTIFICATIONS = "/users/notifications/"
export const ACCEPT_CONNECTION_REQUEST =
  "/api/v1/social-media/user-connections/:id/accept-request/"
export const REJECT_CONNECTION_REQUEST =
  "/api/v1/social-media/user-connections/:id/"
export const INVITE_MEMBER_TO_COMMUNITY =
  "/api/v1/social-media/communities/:id/send-invitation/"
export const COMMUNITY_CATEGORIES = "/api/v1/social-media/community-categories/"
export const REMOVE_COMMUNITY_MEMBER =
  "/api/v1/social-media/communities/:id/remove-member/"
export const MARK_ALL_NOTIFICATIONS_READ =
  "/users/notifications/mark-all-as-read/"
export const SOCIAL_USER_CHAT = "/api/v1/chat/create-user-chat/"
export const GET_USER_PROFILE = "/api/v1/profile/data/"
export const GET_FACEBOOK_AUTH = "/api/v1/social-media/facebook/auth/"
export const REMOVE_FACEBOOK_CONNECTION =
  "/api/v1/social-media/facebook/de_auth/"
export const FACEBOOK_ACCOUNT_PAGES = "/api/v1/social-media/fb-page/"
export const FLEXTIME_CHAT = "/api/v1/chat/create-admin-channel/"
export const GET_SOCIAL_POST = "/api/v1/social-media/posts/:id/"
export const USER_INSIGHTS = "/api/v1/recommendations/insights/"
export const NON_PROFIT_ORGS = "/users/non-profit-organizations/"
export const DONATIONS_LIST =
  "/api/v1/stripe-payment/donations/recurring-donations/"
export const REMOVE_PAYMENT_DONATION =
  "/api/v1/stripe-payment/cancel-subscription/"
export const NON_RECURRING_DONATIONS_LIST = "/api/v1/stripe-payment/donations/"
export const STEPS = "/users/user-onboarding/update/"
export const DISCONNECT_USER = "/api/v1/social-media/user-connections/:id/"
export const CANCEL_CONNECTION_REQ =
  "/api/v1/social-media/user-connections/cancel-request/"
export const CANCEL_COMMUNITY_REQ =
  "/api/v1/social-media/communities/cancel-join-request/"
export const SEARCH_SOCIAL_MEDIA_USER =
  "api/v1/profile/all/?profile_type=social_media_user"
export const UPDATE_PROFILE_DATA = "/api/v1/profile/update-profile/"
export const FAQ="/api/v1/stripe-payment/faqs/"
