import { Fragment } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';

import publicRoutes from './publicRoutes';

import {
  donationRoutes,
  flexTimeEmployerRoutes,
  flexTimeWorkerRoutes,
  socialMediaRoutes,
} from './privateRoutes';

import PublicRouteValidator from './validator/PublicRouteValidator';
import ProtectedRouteValidator from './validator/ProtectedRouteValidator';
import FlexTimeLayout from '../layouts/flex-time/FlexTimeLayout';
import DonationLayout from '../layouts/donation/DonationLayout';
import SocialMediaLayout from '../layouts/social-media/SocialMediaLayout';
import { ROLES } from '../constants/roles';
import { Routes as RoutesConstant } from '../constants/routes';

const rolesBasedRoutes = (role) => {
  switch (role) {
    case ROLES.SOCIAL_USER:
      return (
        <Route element={<SocialMediaLayout />}>
          {socialMediaRoutes.map((route, index) => (
            <Route
              path={route.path}
              key={index}
              element={
                <ProtectedRouteValidator>
                  <route.component />
                </ProtectedRouteValidator>
              }
            />
          ))}
          <Route
            path="*"
            element={<Navigate to={RoutesConstant.HOME} replace />}
          />
        </Route>
      );

    case ROLES.WORKER:
      return (
        <Route element={<FlexTimeLayout />}>
          {flexTimeWorkerRoutes.map((route, index) => (
            <Route
              path={route.path}
              key={index}
              element={
                <ProtectedRouteValidator>
                  <route.component />
                </ProtectedRouteValidator>
              }
            />
          ))}
        </Route>
      );

    case ROLES.EMPLOYER:
      return (
        <Route element={<FlexTimeLayout />}>
          {flexTimeEmployerRoutes.map((route, index) => (
            <Route
              path={route.path}
              key={index}
              element={
                <ProtectedRouteValidator>
                  <route.component />
                </ProtectedRouteValidator>
              }
            />
          ))}
        </Route>
      );
    case ROLES.DONOR:
      return (
        <Route element={<DonationLayout />}>
          {donationRoutes.map((route, index) => (
            <Route
              path={route.path}
              key={index}
              element={
                <ProtectedRouteValidator>
                  <route.component />
                </ProtectedRouteValidator>
              }
            />
          ))}
        </Route>
      );
    default:
      return null;
  }
};

const AppRoutes = () => {
  const role = useSelector((state) =>
    state?.auth?.role ? state?.auth?.role : '',
  );

  return (
    <Fragment>
      <Routes>
        {!role && (
          <>
            {publicRoutes.map((route, index) => (
              <Route
                path={route.path}
                key={index}
                exact
                strict
                element={
                  <PublicRouteValidator>
                    <route.component />
                  </PublicRouteValidator>
                }
              />
            ))}
            <Route
              path="*"
              element={<Navigate to={RoutesConstant.LOGIN} replace />}
            />
          </>
        )}
        {rolesBasedRoutes(role)}
      </Routes>
    </Fragment>
  );
};

export default AppRoutes;
